












































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";

interface Props {
  title: String;
  fromDate: String;
  endDate: String;
  placeholder: String;
}

interface Events {
  onClose: void
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class FilterDate extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  fromDate!: string;

  @Prop({ required: true })
  endDate!: string;

  @Prop({ required: true })
  placeholder!: string;

  localFromDate = '';
  localEndDate = '';

  async created() {
    this.localFromDate = this.fromDate;
    this.localEndDate = this.endDate;
  }

  doFilter() {
    this.$emit('filter', this.localFromDate, this.localEndDate);
  }

  doReset() {
    this.localFromDate = '';
    this.localEndDate = '';
    this.$emit('reset');
  }

  getFilterValue() {
    const from = moment(this.fromDate);
    const to = moment(this.endDate);
    let filter = this.getRangeFilter(from.isValid() ? from.format("MM/DD/YYYY") : "", to.isValid() ? to.format("MM/DD/YYYY") : "");
    let searchKey = '';
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`From: ${min}`);
    }
    if (max) {
      arr.push(`To: ${max}`);
    }
    return arr.join('; ');
  }
}
