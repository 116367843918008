




























































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    LaddaButton
  }
})
export default class TemplateDetailAddModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: '' })
  detailName!: string;

  @Prop({ required: false, default: '' })
  contractNo!: string;

  @Prop({ required: false, default: '' })
  detailNotes!: string;

  @Prop({ required: false, default: false })
  hideQuoteNotes!: boolean;

  @Prop({ required: false, default: '' })
  poInstructions!: string;

  @Prop({ required: false, default: '' })
  endMessage!: string;

  @Prop({ required: false, default: false })
  customTemplateDetail?: boolean;

  @Prop({ required: false, default: false })
  detailEdit?: boolean;

  @Prop({ required: false, default: false })
  pullFromSource?: boolean;

  detailNameError:boolean = false;

  closeModal() {
    this.$emit("close");
  }
  async addDetail() {
    if (!this.detailName.length) {
      this.detailNameError = true;
      return;
    }
    if (this.customTemplateDetail) {
      this.$emit("addNewDetail", {
        "Name": this.detailName,
        "Notes": this.detailNotes,
        "ContractNo": this.contractNo,
        "EndMessage": this.endMessage,
        "POInstructions": this.poInstructions,
        "hideQuoteNotes": this.hideQuoteNotes,
        "customTemplateDetail" : this.customTemplateDetail,
        "pullFromSource" : this.pullFromSource
      });
    } else {
      this.$emit("addNewDetail", {
        "Name": this.detailName,
        "Notes": this.detailNotes,
        "ContractNo": this.contractNo,
        "EndMessage": this.endMessage,
        "POInstructions": this.poInstructions,
        "hideQuoteNotes": this.hideQuoteNotes,
        "pullFromSource": this.pullFromSource
      });
    }
  }
}
