



























































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import directives from "@/helpers/directives";
import PageTitle from "@/components/pageTitle.vue";
import InfoTooltip from "@/components/InfoTooltip.vue";
import LogInfoModal from "@/components/LogInfoModal.vue";
import { notifier,downloadFileUrl } from "@/models/common";

@Component({
  inheritAttrs: false,
  components: { PageTitle, InfoTooltip, LogInfoModal },
  directives
})
export default class QuoteStandardDetail extends TSXComponent<void> {
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  isProcessing = false;
  selectID: any = [];
  details = {
    endOfLifeFormatted: '',
    createDateFormatted: '',
    updateDateFormatted: '',
    totalFormatted: '',
    totalPriceFormatted: '',
    standardName: '',
    specialPricingID: '',
    statusId: 0,
    statusName: '',
    totalDays: 0,
    allowQuotingEndOfLifeDate: 0,
    accounts: [],
    logs: []
  };
  customerCount: number = 0;
  logoDetails: any = [];
  tooltipDetails: any = [];
  tmpStatusId = 0;
  accounts = [];
  items: any[] = [];
  hoveredIndex = null;
  parentLiId = 0;
  showAccountName: boolean = false;
  standardUUID: string = "";
  logModelVisible: boolean = false;

  activeRow(activeId) {
    if (this.parentLiId == activeId) {
      this.parentLiId = 0;
    } else {
      this.parentLiId = activeId;
    }
  }

  checkallLineItems() {
    if (this.selectID.length == 0) {
      this.items.map((item) => {
        this.selectID.push(parseInt(item.STANDARDLIID));
        item.SELECTEDLINEITEM = true;
      });
    } else {
      this.selectID = [];
      this.items.map((item) => {
        item.SELECTEDLINEITEM = false;
      });
    }
  }

  updateSelectedStandardID(event, Id, standarIdx) {
    const idx = this.selectID.indexOf(Id);
    if (event) {
      if (idx === -1) {
        this.selectID.push(Id);
        for (let i = standarIdx + 1; i < this.items.length; i++) {
          const item = this.items[i];
          if (!item.SINCLUDED) break;
          this.$set(item, 'SELECTEDLINEITEM', true);
          this.selectID.push(item.STANDARDLIID);
        }
      }
    } else {
      if (idx !== -1) {
        this.selectID.splice(idx, 1);
        for (let i = standarIdx + 1; i < this.items.length; i++) {
          const item = this.items[i];
          if (!item.SINCLUDED) break;
          this.$set(item, 'SELECTEDLINEITEM', false);
          this.selectID = this.selectID.filter(v => v != item.STANDARDLIID);
        }
      }
    }
  }

  getStatusName(statusId) {
    let statusName = '';
    switch (statusId) {
      case 0:
        statusName = 'Draft';
        break;
      case 1:
        statusName = 'Active';
        break;
      case 2:
        statusName = 'Waiting for Approval';
        break;
      case 3:
        statusName = 'Terminated';
        break;
    }
    return statusName;
  }

  async updateStatus(statusId) {
    this.tmpStatusId = this.details.statusId;
    this.details.statusName = this.getStatusName(statusId);
    this.isProcessing = true;
    const response = await ApiHelper.callApi(
      'post',
      {
        controller: "Quotes",
        FunctionName: "UpdateQuoteStandardStatus",
        selectedIDs: this.standardUUID,
        status: statusId
      }
    );
    this.isProcessing = false;

    if (response.STATUS) {
      this.details.statusId = statusId;
      await this.getDetails();
      notifier.success(`Status Updated Successfully`);
    } else {
      this.details.statusId = this.tmpStatusId;
      this.details.statusName = this.getStatusName(this.tmpStatusId);
      notifier.alert(response.STATUSMESSAGE || 'Cant update Standard Status');
    }
  }

  getSelectedAccounts(ids: string) {
    const selectedIds = (ids ? ids.split(',') : []).map((id) => Number.parseInt(id, 10));
    const selectedAccounts = this.accounts.filter((item: any) => selectedIds.includes(item.AID));
    return selectedAccounts;
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  async created() {
    // await this.getAccounts();
    this.standardUUID = this.$route.params.id || "";
    if (this.standardUUID) {
      await this.getDetails();
    }
  }
  includedPrice = false;
  async getDetails() {
    this.loading = true;
    const response = await ApiHelper.callApi('post', {
      controller: "Quotes",
      FunctionName: "StandardView",
      Content: "Detailed",
      ObjID: this.standardUUID
    });

    if (response.STATUS) {
      this.loading = false;
      const details = response.DETAILS || {};
      this.details.statusId = details.STANDARDSTATUS || 0;
      this.details.statusName = this.getStatusName(details.STANDARDSTATUS);
      this.details.standardName = details.STANDARDNAME || '';
      this.details.specialPricingID = details.SPECIALPRICINGID || '';
      this.details.endOfLifeFormatted = details.ENDOFLIFEFORMATTED || '';
      this.details.createDateFormatted = details.CREATEDATEFORMATTED || '';
      this.details.updateDateFormatted = details.UPDATEDATEFORMATTED || '';
      this.details.totalFormatted = ApiHelper.dollarFormat(details.STANDARDTOTAL || 0);
      this.details.totalPriceFormatted = details.TOTALPRICEFORMATTED || '';
      this.details.totalDays = details.TOTALDAYS || 0;
      this.details.allowQuotingEndOfLifeDate = details.ALLOWQUOTINGENDOFLIFEDATE || 0;
      this.details.logs = details.LOGS || [];

      const STANDARDACCOUNTIDS = details.STANDARDACCOUNTIDS || '';
      const selectedIds = (STANDARDACCOUNTIDS ? STANDARDACCOUNTIDS.split(',') : []).map((id) => Number.parseInt(id, 10));
      this.details.accounts = this.accounts.filter((item: any) => selectedIds.includes(item.AID));
      const items = (response.ITEMS || []);
      this.items = items.map((item) => {
        const price = item.SPRODPRICE || 0;
        const quantity = item.SPRODQUANTITY || 0;
        item.SPRODPRICEFORMATTED = ApiHelper.dollarFormat(price);
        item.TOTALFORMATTED = ApiHelper.dollarFormat(price * quantity);
        item.INCLUDEITEMCOUNT = items.filter((item2) => item2.PARENTLIID == item.STANDARDLIID).length;
        return item;
      });
      const params = JSON.parse(details.STANDARDPARAMS || '{}');
      this.includedPrice = params.includedPrice;
      this.customerCount = response.CUSTOMERCOUNT || 0;
      const accountData = details.ACCOUNTDATA || [];
      this.logoDetails = accountData.filter((item : any, index : number) => index < 5);
      this.tooltipDetails = accountData.filter((item: any, index: number) => index > 4);
    } else {
      ApiHelper.showErrorMessage(response.STATUSMESSAGE || 'Cant get Quote Standard');
      this.$router.replace({
        name: 'QuoteStandards',
      });
    }

  }

  // async getAccounts() {
  //   this.loading = true;
  //   const response = await ApiHelper.callApi("post", {
  //     controller: "Accounts",
  //     FunctionName: "List",
  //     getAll: 1,
  //   });
  //   this.loading = false;
  //   if (response.STATUS) {
  //     const data = response || {};
  //     this.accounts = (data.ACCOUNTS || []);
  //   } else {
  //     ApiHelper.showErrorMessage('Cant get accounts');
  //   }
  // }

  doUpload() {
    ApiHelper.showSuccessMessage('Coming soon', 'Upload');
  }

  showLogs() {
    this.logModelVisible = true;
  }

  async doDownload() {
    const response = await ApiHelper.callApi('post', {
      controller: "Quotes",
      FunctionName: "StandardCSVExport",
      Content: "Detailed",
      ObjID: this.standardUUID
    });
    if (response.STATUS) {
      downloadFileUrl(response.S3URL);
    } else {
      notifier.alert("Export CSV Error: " + response.STATUSMESSAGE)
    }
  }

}
