import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=4d977c76&scoped=true"
import script from "./Dashboard.vue?vue&type=script&lang=tsx"
export * from "./Dashboard.vue?vue&type=script&lang=tsx"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=4d977c76&prod&lang=less"
import style1 from "./Dashboard.vue?vue&type=style&index=1&id=4d977c76&prod&scoped=true&lang=less"
import style2 from "./Dashboard.vue?vue&type=style&index=2&id=4d977c76&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d977c76",
  null
  
)

export default component.exports