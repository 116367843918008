var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-quote"},[_c('div',{staticClass:"row up",attrs:{"id":"list-header"}},[_c('div',{staticClass:"col-2 list-tab left"},[_vm._v("SKU")]),_c('div',{staticClass:"col-3 list-tab left name"},[_vm._v(" Line item Name ")]),_c('div',{staticClass:"col-2 list-tab left"},[_vm._v("Price per")]),(_vm.type && _vm.type == 'standards')?[_c('div',{staticClass:"col-1 list-tab left"},[_vm._v("Qty")]),_c('div',{staticClass:"col-1 list-tab left"},[_vm._v("EOL")])]:[_c('div',{staticClass:"col-2 list-tab left"},[_vm._v("Qty")])],_c('div',{staticClass:"col-2 list-tab left"},[_vm._v("Total")]),_c('div',{staticClass:"col-1 list-tab left"},[_vm._v("Included")])],2),_c('div',{staticClass:"empty-item edit",attrs:{"id":"list-content"}},[_c('div',{attrs:{"id":"list-content-container"}},[_vm._l((_vm.items),function(item,index){return [_c('div',{key:index,staticClass:"list-item row align-items-center",class:{
            'd-none': item.isDeleted,
            hasError: item.hasError
          }},[_c('div',{staticClass:"col-2 left sku col-sku"},[_c('span',{staticClass:"new-quote-sku-input editable-new-quote"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.sku),expression:"item.sku",modifiers:{"trim":true}},{name:"skuOnly",rawName:"v-skuOnly"}],staticClass:"skuInput up",class:{
                    error: item.skuError
                  },staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"type":"text","maxlength":"70","id":("sku_" + index),"placeholder":"Product sku","autocomplete":"off"},domProps:{"value":(item.sku)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "sku", $event.target.value.trim())},function($event){item.skuError = ''; item.hasError = false; _vm.addNewItemRow(index);}],"change":function($event){!_vm.$parent.isFee(item) ? _vm.$parent.SKULookup(index, item.sku, item) : ''},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }!_vm.$parent.isFee(item) ? _vm.$parent.showProductCatTT(item, index, $event) : ''},"focus":function($event){return _vm.addNewItemRow(index)},"blur":function($event){return _vm.$forceUpdate()}}}),(item.sku != '' && !_vm.$parent.isFee(item))?_c('img',{staticClass:"cat-ico",class:{'cat-not-available': (item.ItemCategory || 0) == 0},attrs:{"src":_vm.$parent.categoryImgLink(item),"title":_vm.$parent.getSKUCatTitle(item)},on:{"click":function($event){return _vm.$parent.showProductCatTT(item, index, $event)}}}):_vm._e(),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[(_vm.$parent.productCatTTVisibleIndex === index)?_c('ProductCatTooltip',{staticClass:"agent-tooltip",attrs:{"currentItem":item,"isquote":true,"hideConfigToggle":index == 0 || _vm.$parent.isFee(item),"configCategory":_vm.$parent.configCategory,"showPL":false},on:{"configToggleChange":function($event){return _vm.$parent.configToggleChange(item, index)},"close":function($event){_vm.$parent.productCatTTVisibleIndex = -1}}}):_vm._e()],1)],1)]),_c('div',{staticClass:"col-3 left sku-desc"},[_c('span',{staticClass:"new-quote-name-input editable-new-quote"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.name),expression:"item.name",modifiers:{"trim":true}}],staticClass:"nameInput",class:{
                    error: item.nameError
                  },attrs:{"type":"text","maxlength":"255","placeholder":"Name","autocomplete":"off"},domProps:{"value":(item.name)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "name", $event.target.value.trim())},function($event){item.nameError = ''; item.hasError = false; _vm.addNewItemRow(index);}],"focus":function($event){return _vm.addNewItemRow(index)},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-2 noOverflow left"},[_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput"},[_c('span',{staticClass:"left"},[_vm._v("$")]),_c('vue-numeric',{directives:[{name:"currencyAll",rawName:"v-currencyAll"}],staticClass:"number initial",class:{
                    error: item.priceError
                  },attrs:{"currency":"","separator":",","precision":2,"type":"text","max":999999999.99,"minus":true,"maxlength":12,"disabled":item.disable},on:{"input":function () {
                    item.priceError = '';
                    item.hasError = false;
                    item.priceBk = item.price;
                    _vm.$emit('calcWithChangeCostPrice', index)
                  }},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", _vm._n($$v))},expression:"item.price"}})],1)]),(_vm.type && _vm.type == 'standards')?[_c('div',{staticClass:"col-1 noOverflow left"},[_c('span',{staticClass:"new-quote-quantity-input editable-new-quote"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.quantity),expression:"item.quantity",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:({
                      required:
                        item.sku != '' ||
                        item.name != '' ||
                        item.price == 0,
                      min_value: (item.sku != '' || item.name != '') == true ? 1 : 0
                    }),expression:"{\n                      required:\n                        item.sku != '' ||\n                        item.name != '' ||\n                        item.price == 0,\n                      min_value: (item.sku != '' || item.name != '') == true ? 1 : 0\n                    }"},{name:"digitOnly",rawName:"v-digitOnly"}],staticClass:"number initial",class:{
                      error: item.quantityError || _vm.errors.has(("quantity_" + index))
                    },attrs:{"type":"text","name":("quantity_" + index),"id":("quantity_" + index),"placeholder":"Qty","maxlength":"8","autocomplete":"off","disabled":item.included === 1 || item.isContractFee || item.isFee},domProps:{"value":(item.quantity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "quantity", $event.target.value.trim())},function($event){item.quantityError = ''; item.hasError = false; _vm.$emit('calcWithChangeCostPrice', index);}],"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-1 list-tab left editable-new-quote"},[_c('Datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"className":"pl-2 pr-2","name":"createDate","data-vv-as":"Date","placeholder":"mm/dd/yyyy"},model:{value:(item.endOfLifeDate),callback:function ($$v) {_vm.$set(item, "endOfLifeDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.endOfLifeDate"}})],1)]:[_c('div',{staticClass:"col-2 noOverflow left"},[_c('span',{staticClass:"new-quote-quantity-input editable-new-quote"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.quantity),expression:"item.quantity",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:({
                      required:
                        item.sku != '' ||
                        item.name != '' ||
                        item.price == 0,
                      min_value: (item.sku != '' || item.name != '') == true ? 1 : 0
                    }),expression:"{\n                      required:\n                        item.sku != '' ||\n                        item.name != '' ||\n                        item.price == 0,\n                      min_value: (item.sku != '' || item.name != '') == true ? 1 : 0\n                    }"},{name:"digitOnly",rawName:"v-digitOnly"}],staticClass:"number initial",class:{
                      error: item.quantityError || _vm.errors.has(("quantity_" + index))
                    },attrs:{"type":"text","name":("quantity_" + index),"id":("quantity_" + index),"placeholder":"Qty","maxlength":"8","autocomplete":"off","disabled":item.included === 1 || item.isContractFee || item.isFee},domProps:{"value":(item.quantity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "quantity", $event.target.value.trim())},function($event){item.quantityError = ''; item.hasError = false;}],"blur":function($event){return _vm.$forceUpdate()}}})])])],_c('div',{staticClass:"col-2 noOverflow left"},[_c('span',{class:{ total: true }},[_c('span',[_vm._v("$"+_vm._s(_vm._f("formatCurrency")(_vm.getTotal(item))))])])]),_c('div',{staticClass:"col-1 noOverflow left"},[_c('div',{staticStyle:{"display":"inline-block","width":"50%","text-align":"center"}},[_c('div',{class:{
                  smartCheckBox: true,
                  checkbox_checked: item.included == 1 && index != 0,
                  checkbox_unchecked: item.included != 1 || index == 0,
                  disabled: index == 0
                },on:{"click":function () {
                  _vm.$emit('includeItem', index);
                }}})])]),_c('img',{staticClass:"deleteIcon",staticStyle:{"top":"30px"},attrs:{"src":require("../../assets/images/close_search.png")},on:{"click":function () {
              _vm.$emit('deleteItem', item, index);
            }}})],2)]})],2),_c('div',{staticClass:"clearfix"})])])}
var staticRenderFns = []

export { render, staticRenderFns }