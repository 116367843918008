























































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import axios from "axios";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import ConfirmRemoveCustomerModal from "../components/ConfirmRemoveCustomerModal.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

import customerDetail from "@/components/CustomerDetailsTabDetails.vue";
import CustomerDetailsTabAdvanced from "@/components/CustomerDetailsTabAdvanced.vue";
import customerCommission from "@/components/CustomerDetailsTabCommission.vue";
import customerSecurity from "@/components/CustomerDetailsTabSecurity.vue";
import poIDAutoSuggestInput from "@/components/poIDAutoSuggestInput.vue";
import CustomerAutoSuggestInput from "@/components/CustomerAutoSuggestInput.vue";
import CustomerDetailsTabTags from "@/components/CustomerDetailsTabTags.vue";
import CustomerDetailsTabTemplates from "@/components/CustomerDetailsTabTemplates.vue";
import CustomerDetailsTabAlerts from "@/components/CustomerDetailsTabAlerts.vue";
import ConfirmUpdateCustomerSettingsModal from "@/components/Customer/ConfirmUpdateCustomerSettingsModal.vue";

interface Props {
  alias: string;
  accname: string;
  accid: string;
  aid: number;
  active: boolean;
  items: any;
  selectedTagsID: any;
  selectedSubAccsID: any;
  selectedTags: any;
  alltags: any;
  custSubaccounts: any;
  selectedChangeInfoIds: any;
  selectedSecurityIds: any;
  selectedUserIds: any;
  details: any;
  commAvail: boolean;
  commisionusers: any;
  commissionaccountid: string;
  titleAccName: string;
  allSGComm: any;
  remainingComm: number;
  businessLineOptions: any;
  remainingBusinessLineComm: any;
  businessLines: any;
  businessLineId: any;
  custLoginDetails: any;
  custLoginStatus: any;
  noMultiReseller: any;
  domainsList: any;
  createdPortal: any;
}
interface Events {
  initialLoad: { index };
}
@Component({
  inheritAttrs: false,
  components: {
    ConfirmUpdateCustomerSettingsModal,
    CustomerDetailsTabAlerts,
    CustomerDetailsTabTemplates,
    CustomerDetailsTabTags,
    CustomerAutoSuggestInput, poIDAutoSuggestInput,
    LaddaButton,
    ConfirmRemoveCustomerModal,
    DropdownControl,
    ConfirmRemoveItemModal,
    customerDetail,
    CustomerDetailsTabAdvanced,
    customerCommission,
    customerSecurity
  }
})
export default class CustomerDetailsTabSettings extends TSXComponent<Props> {
  confirmRemoveModalVisible = false;
  selectedID: any;
  saving: boolean | string = false;
  @Prop({ required: false, default: {} })
  settingsItems!: {
    SHIPMENTNOTES: string;
    QUOTENOTES: string;
    ENDMESSAGE: string;
    PURCHASINGINSTRUCTION: string;
    hpDirectTaxRate: string;
    indirectTaxRate: string;
    hardwareTable: string;
    shippingAutoTax: any;
    billingAutoTax: any;
  };

  @Prop({ required: true })
  alias!: string;

  @Prop({ required: true })
  accname!: string;

  @Prop({ required: false, default: '' })
  settingDetTab?: string;

  @Prop({ required: true })
  accid!: string;

  @Prop({ required: true })
  aid!: number;

  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: true })
  items!: any;

  @Prop({ required: false, default: [] })
  hardwareTables!: any;

  @Prop({ required: false, default: [] })
  selectedTagsID!: any;

  @Prop({ required: false, default: [] })
  selectedSubAccsID!: any;

  @Prop({ required: false, default: [] })
  selectedTags!: any;

  @Prop({ required: false, default: [] })
  alltags!: any;

  @Prop({ required: false, default: [] })
  custSubaccounts!: any;

  @Prop({ required: false, default: [] })
  selectedChangeInfoIds!: any;

  @Prop({ required: false, default: [] })
  selectedSecurityIds!: any;

  @Prop({ required: false, default: [] })
  selectedUserIds!: any;

  @Prop({ required: false, default: [] })
  details!: any;

  @Prop({ required: false, default: false })
  commAvail!: boolean;

  @Prop({ required: false, default: [] })
  commisionusers!: any;

  @Prop({ required: false, default: "" })
  commissionaccountid!: string;

  @Prop({ required: false, default: "" })
  titleAccName!: string;

  @Prop({ required: false, default: [] })
  allSGComm!: any;

  @Prop({ required: false, default: 99 })
  remainingComm!: number;

  @Prop({ required: false, default: [] })
  businessLineOptions!: any;

  @Prop({ required: false, default: [] })
  remainingBusinessLineComm!: any;

  @Prop({ required: false, default: false })
  businessLines!: any;

  @Prop({ required: false, default: 0 })
  businessLineId!: any;

  @Prop({ required: false, default: {} })
  custLoginDetails!: any;

  @Prop({ required: false, default: {} })
  custLoginStatus!: any;

  @Prop({ required: false, default: 0 })
  noMultiReseller!: any;

  @Prop({ required: false, default: [] })
  domainsList!: any;

  @Prop({ required: false, default: 0 })
  createdPortal!: any;

  customDataVisible = false;
  fullCustomData: {
    CUSTOMFIELDID?: number;
    CUSTOMFIELDNAME?: string;
    CUSTOMVALUE?: string;
    CUSTOMID?: number;
  }[] = [];
  customDataIndex = -1;
  customDataLoading = false;
  originalFormData: any = {}
  isEdited = false;
  isInitial = true;
  selectedHardwareTable: string[] = [];
  selectedHardwareTableTmp: string[] = [];
  confirmHardwareTableVisible = false;
  confirmNewHardwareTableVisible = false;
  savingHardwareTable: boolean | string = false;
  generateHardware = 0;

  created() {
    this.initData();
  }

  showConfirmRemoveItemModal() {
    this.confirmRemoveModalVisible = true;
  }

  async validateBeforeSubmit(e) {
    var htmlCount = 0;
    if(e) {
      e.preventDefault();
      e.stopPropagation();
    }
    var result = await this.$validator.validateAll();
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
      result = false;
    }
    if (!result) {
      return;
    }
    if(!this.isEdited) {
      return;
    }
    this.saving = true;
    this.isEdited = false;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Accounts",
      FunctionName: "Update",
      aID: this.aid,
      accountID: this.accid,
      ShipmentNotes: this.settingsItems.SHIPMENTNOTES,
      QuoteNotes: this.settingsItems.QUOTENOTES,
      EndMessage: this.settingsItems.ENDMESSAGE,
      PurchasingInstruction: this.settingsItems.PURCHASINGINSTRUCTION,
      accountAlias: this.alias,
      mdEditAccountName: this.accname,
      hpDirectTaxRate: this.settingsItems.hpDirectTaxRate,
      indirectTaxRate: this.settingsItems.indirectTaxRate,
      shippingAutoTax: this.settingsItems.shippingAutoTax === true ? 1 : 0 || 0,
      billingAutoTax: this.settingsItems.billingAutoTax === true ? 1 : 0 || 0
    });
    if (response.data.ERROR) {
      // console.log(response.data.ERROR);
    }
    if (response.data.STATUSSUB == 3) {
      notifier.warning(response.data.MESSAGESUB);
    } else if (response.data.STATUS == 1) {
      this.$emit("initialLoad", 6);
      this.saving = false;
      // notifier.success(response.data.MESSAGE);
      notifier.success('Settings updated successfully.');
      this.originalFormData = { ...this.settingsItems };
      this.isInitial = true;
    } else if (response.data.STATUS == 0) {
      this.saving = "error";
    }
  }
  showCustomEditBox(index) {
    this.customDataIndex = index;

    setTimeout(() => {
      $(".custom-data-txt")
        .focus()
        .select();
    }, 100);
  }
  hideCustomEditBox() {
    this.customDataIndex = -1;
  }
  async updateCustomData(item) {
    //remove HTML
    // item.CUSTOMVALUE = this.removeHTML(item.CUSTOMVALUE);
    if (item.CUSTOMVALUE != "") {
      if (htmlCheck(item.CUSTOMVALUE)) {
        item.CUSTOMVALUE = htmlParse(item.CUSTOMVALUE);
        notifier.alert("HTML content found. This content has been removed!");
      }
    }

    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        customID: item.CUSTOMID,
        customFieldID: item.CUSTOMFIELDID,
        customValue: item.CUSTOMVALUE,
        Id: this.aid
      });

      if (response.data.STATUS == 1) {
        this.customDataIndex = -1;
        if (item.CUSTOMVALUE == "") {
          //delete this item
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = 0;
        } else if (typeof response.data.NEWCUSTOMID != "undefined") {
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = response.data.NEWCUSTOMID;
        }
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      // this.customDataLoading = false;
    }
  }
  async showCustomData() {
    this.customDataVisible = !this.customDataVisible;
    if (!this.customDataVisible) {
      return false;
    }
    // if (this.customDataVisible) {
    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomData",
        DataType: "4",
        Id: this.aid
      });
      if (response.data.STATUS != 1) {
        return false;
      }
      if (response.data.STATUS == 1) {
        this.fullCustomData = [];
        for (var val of response.data.CUSTOMDEFINITION) {
          let tmpValue = response.data.CUSTOMDATA.filter(
            tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID
          );
          this.fullCustomData.push({
            CUSTOMFIELDID: val.CUSTOMFIELDID,
            CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
            CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
            CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0
          });
        }
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
    // }
  }
  async deleteAccount(account) {
    /* this.saving = true; */
    this.confirmRemoveModalVisible = false;
    const reqBody = {
      session: sessionStorage.getItem("sessionID"),
      userRole: sessionStorage.getItem("userRole"),
      Controller: "customers",
      FunctionName: "Delete",
      deleteCustomerAcc: "true",
      selectedIDs: account
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);
    if (response.data.STATUS === 1) {
      this.saving = false;
      this.$router.push({ name: "Customers" });
    }
  }

  @Watch("settingsItems", { deep: true})
  async isFormChanged() {
    if (this.isInitial) {
      // selected hardwareTable
      const hardwareTable = this.settingsItems.hardwareTable || "";
      if (hardwareTable) {
        this.selectedHardwareTable = [hardwareTable];
      }
      this.generateHardware = parseInt(this.custLoginDetails.GENERATEHARDWARE) || 0;
      this.isInitial = false;
    }
    let result = await this.$validator.validateAll();
    this.isEdited = Object.keys(this.settingsItems).some(
      key => {
        if(key == "hpDirectTaxRate" || key == "indirectTaxRate") {
          return parseFloat(this.settingsItems[key]) !== this.originalFormData[key];
        } else if(key == "shippingAutoTax" || key == "billingAutoTax"){
          return this.settingsItems[key] !== this.originalFormData[key];
        } else {
          return this.settingsItems[key] !== this.originalFormData[key];
        }
      }
    );
    if (!result) {
      this.isEdited = false;
    }
  }

  async selectHardwareTable(addNew = false) {
    if(!this.selectedHardwareTableTmp.length) return;

    // update Accounts.hardwareTable
    const hardwareTable = this.selectedHardwareTableTmp[0].toUpperCase().replace(/\s/g, "");
    const action = addNew ? "add" : "update";
    try {
      this.savingHardwareTable = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Accounts",
        FunctionName: "UpdateHardwareTable",
        aId: this.aid,
        hardwareTable,
        action
      });
      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1) {
        this.selectedHardwareTable = [hardwareTable];
        if (addNew) {
          this.hardwareTables.push(hardwareTable);
        }
        if (message) {
          notifier.success(message);
        }
        
        // reset
        this.savingHardwareTable = false;
        this.selectedHardwareTableTmp = [];
        this.confirmHardwareTableVisible = false;
        this.confirmNewHardwareTableVisible = false;
      }else {
        this.savingHardwareTable = "error";
      }
    } catch (err) {
      console.log(err);
      this.savingHardwareTable = "error";
    }
  }

  shippingAutoTaxChange() {
    if (this.settingsItems.shippingAutoTax === true) {
      this.settingsItems.billingAutoTax = false;
    }
  }

  billingAutoTaxChange() {
    if (this.settingsItems.billingAutoTax === true) {
      this.settingsItems.shippingAutoTax = false;
    }
  }

  async generateHardwareChange() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      Controller: "Customers",
      FunctionName: "UpdateAccountParams",
      aID: this.aid,
      action: "saveGenerateHardware",
      generateHardware: this.generateHardware
    });
    if (response.data.STATUS == 1) {
      notifier.success(response.data.STATUSMSG);
    }
  }

  initData() {
    this.originalFormData = { ...this.settingsItems };
      // selected hardwareTable
      const hardwareTable = this.settingsItems.hardwareTable || "";
      if (hardwareTable) {
        this.selectedHardwareTable = [hardwareTable];
      }
      this.generateHardware = parseInt(this.custLoginDetails.GENERATEHARDWARE) || 0;
      this.isInitial = true;
  }

  mounted() {
    this.customerDetail = this.$refs.customerDetail;
    this.customerDetailsTabTemplates = this.$refs.customerDetailsTabTemplates;
    this.customerDetailsTabTags = this.$refs.customerDetailsTabTags;
    this.customerSecurity = this.$refs.customerSecurity;
  }
  showConfirmationPopup = false;
  customerDetail: any = null;
  customerDetailsTabTemplates: any = null;
  customerDetailsTabTags: any = null;
  customerSecurity: any = null;
  doUpdateSections() {
    const actions: any[] = [];
    if (this.isEdited) {
      actions.push(this.validateBeforeSubmit(null));
    }
    if(this.customerDetail && this.customerDetail.isEdited) {
      actions.push(this.customerDetail.validateBeforeSubmit(null));
    }
    if(this.customerDetailsTabTemplates && this.customerDetailsTabTemplates.isEdited) {
      actions.push(this.customerDetailsTabTemplates.validateBeforeSubmit(null));
    }
    if(this.customerDetailsTabTags && this.customerDetailsTabTags.isEdited) {
      actions.push(this.customerDetailsTabTags.validateBeforeSubmit(null));
    }
    if(this.customerSecurity && this.customerSecurity.isUserEdited) {
      actions.push(this.customerSecurity.validateBeforeSubmit(null));
    }
    if(actions.length) {
      Promise.all(actions).then((results) => {
        console.log('actions.results', results);
        this.isEdited = false;
        this.customerDetail.isEdited = false;
        this.customerDetailsTabTemplates.isEdited = false;
        this.customerDetailsTabTags.isEdited = false;
        this.customerSecurity.isUserEdited = false;
        this.showConfirmationPopup = false;
      });
    }
  }
  hasEditedSections() {
    return this.isEdited || (
        this.customerDetail && this.customerDetail.isEdited
    ) || (
        this.customerDetailsTabTemplates && this.customerDetailsTabTemplates.isEdited
    ) || (
        this.customerDetailsTabTags && this.customerDetailsTabTags.isEdited
    ) || (
        this.customerSecurity && this.customerSecurity.isUserEdited
    );
  }
}
