




































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: QuoteStatusValue;
}

interface Events {
  onChangeStatus: QuoteStatusValue
}

export enum QuoteStatusValue {
  Open = 1,//0 or 1
  Close = 2,
  Purchased = 5
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class QuoteStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: QuoteStatusValue;

  @Prop({ required: false, default: true })
  showStatus?: boolean;

  currentRole = sessionStorage.getItem("userRole");
  popoverVisible = false
  optionsVisible = false

  get iconClass() {
    if(this.status == QuoteStatusValue.Open || this.status == 0){
      return 'green'
    }
    else if(this.status == QuoteStatusValue.Purchased){
      return 'blue'
    }
    else if(this.status == 6){
      return 'purple'
    }
    else if(this.status == 7){
      return 'lightPurple'
    }
    else if(this.status == 8){
      /* if(this.currentRole == "Reseller") { */
        return 'lightBlue'
      /* }else {
        // customer? same as purchased
        return 'blue';
      } */
    }
    else{
      return 'red'
    }
  }

  get statusText() {
    if(this.status == QuoteStatusValue.Open || this.status == 0){
      return 'Open'
    }
    else if(this.status == QuoteStatusValue.Purchased){
      return 'Purchased'
    }
    else if(this.status == 6){
      return 'Requested'
    }
    else if(this.status == 7){
      return 'Assigned'
    }
    else if(this.status == 8){
      /* if(this.currentRole == "Reseller") { */
        return 'Purchase Pending'
      /* }else {
        // customer? same as purchased
        return 'Purchased'
      } */
    }
    else{
      return 'Closed'
    }
  }
  hideTooltip() {
    this.optionsVisible = false;
  }
  mouseover() {
    if (this.optionsVisible) {
      return
    }

    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false
    
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: QuoteStatusValue) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }
}
