
































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import axios from "axios";
import PageTitle from "../components/pageTitle.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import DropdownControl from "../components/DropdownControl.vue";

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    PageTitle,
    ConfirmRemoveItemModal,
    DropdownControl
  }
})
export default class SourceAdd extends TSXComponent<void> {
  $systemGroups: any;
  loading = false;
  sourceId = 0;
  sourceType = "source";
  source = {
    sourceName: "",
    accountNumber: "",
    salesContactName: "",
    salesContactEmail: "",
    salesContactPhone: "",
    financeContactName: "",
    financeContactEmail: "",
    financeContactPhone: "",
    notes: ""
  };
  fundingSource = {
    sourceName: "",
    accountNumber: "",
    terms: "",
    creditLimit: "",
    notes: ""
  };
  saving: string | boolean = false;
  sourceDetails: any = {};
  fundingSourceDetails: any = {};
  confirmRemoveModalVisible = false;
  deleting: string | boolean = false;
  fundingSourceItems: any = [];
  selectedSourceFundingIds: any = [];

  async created() {
    const query: any = this.$route.query;
    if (
      query.sourceType &&
      ["source", "funding_source"].includes(query.sourceType)
    ) {
      this.sourceType = query.sourceType;
    }
    if (parseInt(this.$route.params.id) > 0) {
      this.sourceId = parseInt(this.$route.params.id);
      await this.fetchData();
    }
  }

  async fetchData() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Sources",
        FunctionName: "Edit",
        action: "getSource",
        sourceId: this.sourceId,
        sourceType: this.sourceType
      });
      if (response.data.STATUS) {
        this.sourceDetails = response.data.sourceDetails || {};
        if (this.sourceDetails.SOURCEID) {
          if (this.sourceType == "source") {
            this.source = {
              sourceName: this.sourceDetails.SOURCENAME || "",
              accountNumber: this.sourceDetails.ACCOUNTNUMBER || "",
              salesContactName: this.sourceDetails.SALESCONTACTNAME || "",
              salesContactEmail: this.sourceDetails.SALESCONTACTEMAIL || "",
              salesContactPhone: this.sourceDetails.SALESCONTACTPHONE || "",
              financeContactName: this.sourceDetails.FINANCECONTACTNAME || "",
              financeContactEmail: this.sourceDetails.FINANCECONTACTEMAIL || "",
              financeContactPhone: this.sourceDetails.FINANCECONTACTPHONE || "",
              notes: this.sourceDetails.NOTES || ""
            };
            this.fundingSourceItems = response.data.fundingSourceItems || [];
            if (this.sourceDetails.DEFAULTFUNDINGSOURCE || 0) {
              this.selectedSourceFundingIds = [
                parseInt(this.sourceDetails.DEFAULTFUNDINGSOURCE)
              ];
            }
          } else if (this.sourceType == "funding_source") {
            this.fundingSource = {
              sourceName: this.sourceDetails.SOURCENAME || "",
              accountNumber: this.sourceDetails.ACCOUNTNUMBER || "",
              terms: this.sourceDetails.TERMS || "",
              creditLimit: this.sourceDetails.CREDITLIMIT || "",
              notes: this.sourceDetails.NOTES || ""
            };
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async saveSource() {
    // validate
    const valid = await this.$validator.validateAll();
    if (!valid) {
      return;
    }

    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Sources",
        FunctionName: "Edit",
        sourceId: this.sourceId,
        sourceType: this.sourceType,
        sourceInfo:
          this.sourceType == "source" ? this.source : this.fundingSource,
        defaultFundingSource: this.selectedSourceFundingIds.length
          ? this.selectedSourceFundingIds[0]
          : 0
      });
      if (response.data.STATUS) {
        this.saving = false;
        // back to list
        this.$router.push({
          name: "Sources",
          query: { sourceType: this.sourceType }
        });
        notifier.success("Saved successfully");
      } else {
        this.saving = "error";
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (err) {
      this.saving = "error";
      console.log(err);
    }
  }

  get pageTitle() {
    let ret = "";

    if (this.sourceId) {
      if (this.sourceDetails.SOURCENAME) {
        ret = this.sourceDetails.SOURCENAME;
      } else if (this.fundingSourceDetails.SOURCENAME) {
        ret = this.fundingSourceDetails.SOURCENAME;
      }
    } else {
      ret = this.sourceType == "source" ? "Add Source" : "Add Funding Source";
    }

    return ret;
  }

  get allowEdit() {
    return this.$systemGroups.includes("financial_admin");
  }

  async removeSource() {
    if (!this.sourceId) return;

    try {
      this.deleting = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Sources",
        FunctionName: "Edit",
        action: "delete",
        sourceId: this.sourceId,
        sourceType: this.sourceType
      });
      if (response.data.STATUS) {
        this.deleting = false;

        // back to list
        this.$router.push({
          name: "Sources",
          query: { sourceType: this.sourceType }
        });
        notifier.success("Deleted successfully");
      } else {
        this.saving = "error";
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (err) {
      this.deleting = "error";
      console.log(err);
    }
  }
}
