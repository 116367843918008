













































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { validateFn } from "../helpers";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import AccountTagSelect from "@/components/AccountTagSelect.vue";
import SecurityMultiSelect from "@/components/SecurityMultiSelect.vue";
import SelectTagControl from "@/components/SelectTagControl.vue";
import TagMultiselect from "../components/TagMultiselect.vue";
import directives from "@/helpers/directives";
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";

interface Tag {
  CUSTOMFIELDOPTIONID: number;
  CUSTOMFIELDOPTIONNAME: string;
}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    AccountTagSelect,
    SecurityMultiSelect,
    SelectTagControl,
    TagMultiselect,
    Datepicker
  },
  directives
})
export default class ReportBuilder extends TSXComponent<void> {
  loading = false;
  updating: any = false;
  selectedUserIds: number[] = [];
  // account tags
  alltags: any = [];
  selectedtags: any = [];
  selectedtagsid: number[] = [];
  // security groups
  selectedGroupIds: number[] = [];
  selectedGroups: any = [];
  ALLSECURITYGROUPS: any = [];
  // accounts
  allAccounts: any = [];
  selectedAIds: number[] = [];
  selectedAccounts: any = [];

  // output columns
  selectedColumnIds: number[] = [];
  selectedColumns: any[] = [];

  // content statuses
  selectedStatuIds: number[] = [];
  selectedStatuses: any[] = [];

  reportName = "";
  // contentType = "";
  reportStatus = -1;
  filters: any = {
    contentType: "",
    outputColumns: [],
    createDate: {
      active: false,
      timeFrame: "",
      timeFrameValue: "",
      ignoreFutureDates: false,
      startDate: "",
      endDate: ""
    },
    statusNotChangedIn: {
      active: false,
      timeFrame: "",
      timeFrameValue: ""
    },
    onSpecificStatus: {
      active: false,
      customStatus: []
    },
    securityGroups: {
      active: false,
      groupIds: []
    },
    tags: {
      active: false,
      tagIds: []
    },
    accounts: {
      active: false,
      aIds: []
    }
  };
  details: any = {
    view: "",
    execution: "",
    scheduled: {
      timeFrame: "",
      timeFrameValue: "",
      emailList: ""
    }
  };

  $refs!: {
    AccountTagSelect: AccountTagSelect,
    startDatepicker: Datepicker,
    endDatepicker: Datepicker
  }

  get outputColumns(): Tag[] {
    let options: any[] = this.initOutputColumns();

    return options;
  }

  get contentStatuses(): Tag[] {
    let options: any[] = this.initContentStatuses();

    return options;
  }

  async created() {
    // get report builder details
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      Controller: "Reports",
      FunctionName: "ReportBuilderView",
      reportId: parseInt(this.$route.params.id) || 0
    });
    if (response.data.STATUS == 1) {
      this.alltags = response.data.ACCOUNTTAGS || [];
      this.ALLSECURITYGROUPS = response.data.ALLSECURITYGROUPS || [];
      this.allAccounts = response.data.allAccounts || [];
      const reportDetails = response.data.reportDetails || {};
      this.reportName = reportDetails.REPORTNAME || "";
      this.reportStatus = [0, 1].includes(reportDetails.REPORTSTATUS)
        ? reportDetails.REPORTSTATUS
        : -1;
      // filters
      const filters =
        reportDetails.REPORTPARAMS && reportDetails.REPORTPARAMS.filters
          ? reportDetails.REPORTPARAMS.filters
          : this.filters;
      this.filters.contentType = filters.contentType || "";
      this.filters.outputColumns = filters.outputColumns || [];
      this.selectedColumnIds = this.filters.outputColumns.map(
        column => column.CUSTOMFIELDOPTIONID
      );
      this.selectedColumns = this.filters.outputColumns;

      this.filters.createDate = {
        active: filters.createDate.active || false,
        timeFrame: filters.createDate.timeFrame,
        timeFrameValue: filters.createDate.timeFrameValue,
        ignoreFutureDates: filters.createDate.ignoreFutureDates || false,
        startDate: filters.createDate.startDate || "",
        endDate: filters.createDate.endDate || ""
      };
      if (moment(this.filters.createDate.startDate).isValid()) {
        this.$refs.startDatepicker.setDate(this.filters.createDate.startDate);
      }
      if (moment(this.filters.createDate.endDate).isValid()) {
        this.$refs.endDatepicker.setDate(this.filters.createDate.endDate);
      }
      this.filters.statusNotChangedIn = {
        active: filters.statusNotChangedIn.active || false,
        timeFrame: filters.statusNotChangedIn.timeFrame,
        timeFrameValue: filters.statusNotChangedIn.timeFrameValue
      };

      this.filters.onSpecificStatus = {
        active: filters.onSpecificStatus
          ? filters.onSpecificStatus.active || false
          : false,
        customStatus:
          filters.onSpecificStatus && filters.onSpecificStatus.customStatus
            ? filters.onSpecificStatus.customStatus
            : []
      };

      if (this.filters.onSpecificStatus.active) {
        this.selectedStatuIds = this.filters.onSpecificStatus.customStatus.map(
          status => status.CUSTOMFIELDOPTIONID
        );
        this.selectedStatuses = this.filters.onSpecificStatus.customStatus;
      }

      // init selected security groups
      const groupIds = filters.securityGroups.groupIds || [];
      this.filters.securityGroups = {
        active: filters.securityGroups.active || false,
        groupIds
      };
      if (groupIds.length) {
        for (const id of groupIds) {
          const inList = this.ALLSECURITYGROUPS.find(
            item => item.SECURITYGROUPID == id
          );
          if (inList) {
            this.selectedGroupIds.push(inList.SECURITYGROUPID);
            this.selectedGroups.push({
              SECURITYGROUPNAME: inList.SECURITYGROUPNAME,
              SECURITYGROUPID: inList.SECURITYGROUPID
            });
          }
        }
      }
      // init selected tags
      const tagIds = filters.tags.tagIds || [];
      this.filters.tags = {
        active: filters.tags.active || false,
        tagIds
      };
      if (tagIds.length) {
        for (const id of tagIds) {
          const inList = this.alltags.find(item => item.ACCOUNTTAGID == id);
          if (inList) {
            this.selectedtagsid.push(inList.ACCOUNTTAGID);
            this.selectedtags.push({
              ACCOUNTTAGID: inList.ACCOUNTTAGID,
              TAGNAME: inList.TAGNAME
            });
          }
        }
      }
      // init selected accounts
      if (filters.accounts) {
        const aIds = filters.accounts.aIds || [];
        this.filters.accounts = {
          active: filters.accounts.active || false,
          aIds
        };
        if (aIds.length) {
          for (const id of aIds) {
            const inList = this.allAccounts.find(item => item.AID == id);
            if (inList) {
              this.selectedAIds.push(inList.AID);
              this.selectedAccounts.push({
                ANAME: inList.ANAME,
                AID: inList.AID
              });
            }
          }
        }
      }
      // details
      const details =
        reportDetails.REPORTPARAMS && reportDetails.REPORTPARAMS.details
          ? reportDetails.REPORTPARAMS.details
          : this.details;
      this.details.view = details.view || "";
      this.details.execution = details.execution || "";
      this.details.scheduled.timeFrame = details.scheduled.timeFrame || "";
      this.details.scheduled.timeFrameValue =
        details.scheduled.timeFrameValue || "";
      this.details.scheduled.emailList = details.scheduled.emailList || "";
    }
  }

  toggleSelectedTags(selectedID: number, tagName) {
    const index = this.selectedtagsid.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedtagsid.push(selectedID);
      this.selectedtags.push({
        ACCOUNTTAGID: selectedID,
        TAGNAME: tagName
      });
    } else {
      this.selectedtagsid.splice(index, 1);
      this.$delete(this.selectedtags, index);
    }
    this.filters.tags.tagIds = this.selectedtagsid;
  }

  selectAllTags() {
    this.resetTags();
    for (const item of this.alltags) {
      this.selectedtagsid.push(item.ACCOUNTTAGID);
      this.selectedtags.push({
        ACCOUNTTAGID: item.ACCOUNTTAGID,
        TAGNAME: item.TAGNAME
      });
    }
    this.filters.tags.tagIds = this.selectedtagsid;
  }

  resetTags() {
    this.selectedtags = [];
    this.selectedtagsid = [];
    this.filters.tags.tagIds = this.selectedtagsid;
  }

  updateTagList(type, index, tagID) {
    if (this.selectedtagsid.length && type == "delete") {
      this.$delete(this.selectedtagsid, index);
      this.$delete(this.selectedtags, index);
    }
    this.filters.tags.tagIds = this.selectedtagsid;
  }

  async createNewTag(tagName) {
    if (tagName != "") {
      const AccountTagSelect: any = this.$refs.AccountTagSelect;
      try {
        AccountTagSelect.creating = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Customers",
          FunctionName: "AccountTagsUpdate",
          action: "create_new_tag",
          tagName: tagName
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
          throw new Error(response.data.STATUSMESSAGE);
        }

        if (response.data.STATUS == 1 && response.data.NEWTAGID) {
          this.selectedtagsid.push(response.data.NEWTAGID);
          this.selectedtags.push({
            ACCOUNTTAGID: response.data.NEWTAGID,
            TAGNAME: tagName
          });
          AccountTagSelect.search = "";
        }
        AccountTagSelect.creating = false;
      } catch (err) {
        console.log(err.message);
      } finally {
        AccountTagSelect.creating = false;
      }
    }
  }

  toggleSelectedGroupId(groupId, groupName) {
    const index = this.selectedGroupIds.findIndex(id => id === groupId);
    if (index === -1) {
      this.selectedGroupIds.push(groupId);
      this.selectedGroups.push({
        SECURITYGROUPNAME: groupName,
        SECURITYGROUPID: groupId
      });
    } else {
      this.selectedGroupIds.splice(index, 1);
      this.$delete(this.selectedGroups, index);
    }
    this.filters.securityGroups.groupIds = this.selectedGroupIds;
  }

  selectAllGroups() {
    this.resetGroups();
    for (const item of this.ALLSECURITYGROUPS) {
      this.selectedGroupIds.push(item.SECURITYGROUPID);
      this.selectedGroups.push({
        SECURITYGROUPNAME: item.SECURITYGROUPNAME,
        SECURITYGROUPID: item.SECURITYGROUPID
      });
    }
    this.filters.securityGroups.groupIds = this.selectedGroupIds;
  }

  resetGroups() {
    this.selectedGroupIds = [];
    this.selectedGroups = [];
    this.filters.securityGroups.groupIds = this.selectedGroupIds;
  }

  updateSGList(type, index, id) {
    if (this.selectedGroups.length && type == "delete") {
      this.$delete(this.selectedGroups, index);
      this.$delete(this.selectedGroupIds, index);
    }
    this.filters.securityGroups.groupIds = this.selectedGroupIds;
  }

  async saveReport() {
    const reportId = parseInt(this.$route.params.id) || 0;

    // validate
    let valid = await this.$validator.validateAll();
    if (!valid) {
      return;
    }

    try {
      this.updating = true;

      this.filters.outputColumns = this.selectedColumns;
      this.filters.onSpecificStatus.customStatus = this.selectedStatuses;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Reports",
        FunctionName: "UpdateReportBuilder",
        reportId,
        reportName: this.reportName,
        // reportContent: this.contentType,
        reportStatus: this.reportStatus,
        reportParams: {
          isReportBuilder: 1,
          filters: this.filters,
          details: this.details
        }
      });
      if (response.data.STATUS == 1) {
        this.updating = false;
        const message = !reportId
          ? "Created successfully"
          : "Updated successfully";
        notifier.success(message);

        const query = this.$route.query;
        if ((query.from || "") == "details") {
          // go to report details page
          this.$router.replace({
            name: "ReportBuilderDetails",
            params: {
              id: this.$route.params.id
            }
          });
        } else {
          // goto reports page
          this.$router.push({
            name: "Reports"
          });
        }
      } else {
        this.updating = "error";
        const errorCode = response.data.errorCode || "";
        const message = response.data.STATUSMESSAGE || "";
        if (errorCode == "name_existed" && message) {
          notifier.alert(message);
        }
      }
    } catch (err) {
      this.updating = "error";
      console.log(err.message);
    }
  }

  toggleSelectedAId(aId, aName) {
    const index = this.selectedAIds.findIndex(id => id === aId);
    if (index === -1) {
      this.selectedAIds.push(aId);
      this.selectedAccounts.push({
        ANAME: aName,
        AID: aId
      });
    } else {
      this.selectedAIds.splice(index, 1);
      this.$delete(this.selectedAccounts, index);
    }
    this.filters.accounts.aIds = this.selectedAIds;
  }

  selectAllAccounts() {
    this.resetAccounts();
    for (const item of this.allAccounts) {
      this.selectedAIds.push(item.AID);
      this.selectedAccounts.push({
        ANAME: item.ANAME,
        AID: item.AID
      });
    }
    this.filters.accounts.aIds = this.selectedAIds;
  }

  resetAccounts() {
    this.selectedAIds = [];
    this.selectedAccounts = [];
    this.filters.accounts.aIds = this.selectedAIds;
  }

  updateAccountList(type, index, id) {
    if (this.selectedAccounts.length && type == "delete") {
      this.$delete(this.selectedAccounts, index);
      this.$delete(this.selectedAIds, index);
    }
    this.filters.accounts.aIds = this.selectedAIds;
  }

  toggleSelectedColumnId(columnId, columnName, selectedColumn) {
    const index = this.selectedColumnIds.findIndex(id => id === columnId);
    if (index === -1) {
      this.selectedColumnIds.push(columnId);
      this.selectedColumns.push(selectedColumn);
      return;
    }
    this.selectedColumnIds.splice(index, 1);
    this.$delete(this.selectedColumns, index);
  }

  selectAllColumns() {
    this.resetColumns();

    const options = this.initOutputColumns();
    for (const column of options) {
      this.selectedColumnIds.push(column.CUSTOMFIELDOPTIONID);
      this.selectedColumns.push(column);
    }
  }

  resetColumns() {
    this.selectedColumnIds = [];
    this.selectedColumns = [];
  }

  toggleSelectedStatusId(statusId, statusName, selectedStatus) {
    const index = this.selectedStatuIds.findIndex(id => id === statusId);
    if (index === -1) {
      this.selectedStatuIds.push(statusId);
      this.selectedStatuses.push(selectedStatus);
      return;
    }
    this.selectedStatuIds.splice(index, 1);
    this.$delete(this.selectedStatuses, index);
  }

  selectAllStatuses() {
    this.resetStatuses();

    const options = this.initContentStatuses();
    for (const status of options) {
      this.selectedStatuIds.push(status.CUSTOMFIELDOPTIONID);
      this.selectedStatuses.push(status);
    }
  }

  resetStatuses() {
    this.selectedStatuIds = [];
    this.selectedStatuses = [];
  }

  initOutputColumns() {
    let options: any[] = [];
    let predefinedOptions: string[] = [];
    let indexStart = 0;

    if (this.filters.contentType === "quotes") {
      indexStart = 100;
      predefinedOptions = [
        "Status",
        "SKU",
        "Description",
        "Quote Name",
        "Quote Number",
        "Customer",
        "Subtotal",
        "Source",
        "Grand Total",
        "Date",
        "Quote Creator",
        "Source Quote ID",
        "System",
        "Business Line",
        "Product Line",
        "Cost Per",
        "Price Per",
        "Product Category"
      ];
    } else if (this.filters.contentType === "orders") {
      indexStart = 200;
      predefinedOptions = [
        "SKU Status",
        "Order Name",
        "Customer",
        "Customer PO",
        "Var Cost",
        "Var360 Order ID",
        "Created Date",
        "Profit",
        "Tax",
        "Subtotal",
        "Grand Total",
        "Source",
        "Source Quote ID",
        "Var PO",
        "Source Order Number",
        "Payroll %",
        "Invoiced %",
        "Contract Number",
        "System",
        "Security Group",
        "Global Tags",
        "Business Line",
        "COG Not Billed",
        "Sales Not Invoiced",
        "SKU",
        "Description",
        "Serial Number",
        "Asset Tag",
        "End User Name",
        "Address",
        "Product Line",
        "Cost Per",
        "Price Per",
        "Product Category",
        "Last Modified",
        "Ship Date",
        "Invoice Number",
        "Warranty Name",
        "Warranty Start Date",
        "Warranty End Date",
        "Warranty Last Checked",
        "Order Status"
      ];
    } else if (this.filters.contentType === "invoices") {
      indexStart = 300;
      predefinedOptions = [
        "Invoice ID",
        "Customer PO",
        "Customer",
        "Grand Total",
        "Tax",
        "Invoice Profit",
        "Invoice Date",
        "Paid Date",
        "Due Date",
        "Status",
        "Invoice %",
        "Invoice Email",
        "Reported",
        "Source",
        "Invoice Type",
        "Billing Address",
        "Billing State"
      ];
    }

    for (const option of predefinedOptions) {
      indexStart++;
      options.push({
        CUSTOMFIELDOPTIONID: indexStart,
        CUSTOMFIELDOPTIONNAME: option,
        columnKey: option.replaceAll(" ", ""),
        columnName: option
      });
    }

    return options;
  }

  initContentStatuses() {
    let options: any[] = [];
    let predefinedOptions: any[] = [];

    if (this.filters.contentType === "quotes") {
      predefinedOptions = [
        { id: 6, name: "Requested" },
        { id: 1, name: "Open" },
        { id: 7, name: "Assigned" },
        { id: 8, name: "Purchase Pending" },
        { id: 5, name: "Purchased" },
        { id: 2, name: "Close" }
      ];
    } else if (this.filters.contentType === "orders") {
      predefinedOptions = [
        { id: 1, name: "No Status" },
        { id: 2, name: "Delivered" },
        { id: 3, name: "Shipped" },
        { id: 4, name: "Estimated Shipment" },
        { id: 5, name: "Production" },
        { id: 6, name: "Placed" }
      ];
    } else if (this.filters.contentType === "invoices") {
      predefinedOptions = [
        { id: 0, name: "Sent" },
        { id: 1, name: "Paid" },
        { id: 2, name: "Created" },
        { id: 3, name: "PartialPay" },
        { id: 4, name: "Void" }
      ];
    }

    for (const option of predefinedOptions) {
      options.push({
        CUSTOMFIELDOPTIONID: option.id,
        CUSTOMFIELDOPTIONNAME: option.name,
        statusKey: option.id,
        statusName: option.name
      });
    }

    return options;
  }
}
