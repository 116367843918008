





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { InvoiceAPIRepo, InvoiceStatus } from "../repos/InvoiceAPIRepo";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
const Handlebars = require("handlebars/runtime.js");
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import { microserviceQBOURL } from "../models/envVars";
import InvoicePaidConfirmModal from "../components/InvoicePaidConfirmModal.vue";
import InvoiceMail from "../components/InvoiceMail.vue";
import viewInvoiceModel from "../components/viewInvoiceModel.vue";
import axios from "axios";
import ErrorView from "./Error.vue";
import LaddaButton from "../components/LaddaButton.vue";
import InvoiceVoidReason from "../components/InvoiceVoidReason.vue";
import CustomAlertModal from "@/components/Alert/CustomAlertModal.vue";
import ConfirmProfitTotalModal from "../components/ConfirmProfitTotalModal.vue";
import PageTitle from "../components/pageTitle.vue";
import InvoiceRefund from "../components/InvoiceRefund.vue";
import { getEUAddress, priceTypeStr } from "@/helpers/ApiHelper";
import SubOrderEndUserCustomData from "@/components/Order/SubOrderEndUserCustomData.vue";
import { ApiHelper } from "@/helpers/all";
import moment from "moment";

declare function require(params: any): any;
declare const htmlParse: Function;
declare const htmlCheck: Function;
declare const dataURL: string;
const invoiceRepo = new InvoiceAPIRepo();
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {
    ExportModal,
    ConfirmRemoveItemModal,
    InvoicePaidConfirmModal,
    InvoiceMail,
    viewInvoiceModel,
    ErrorView,
    LaddaButton,
    InvoiceVoidReason,
    ConfirmProfitTotalModal,
    PageTitle,
    InvoiceRefund,
    SubOrderEndUserCustomData,
    CustomAlertModal
  },
  methods: {
    getEUAddress,
    priceTypeStr
  }
})
export default class InvoiceDetails extends TSXComponent<void> {
  $route: any;
  progress = 1;
  currentRole = sessionStorage.getItem("userRole");
  isActive = "invoiceDetails";
  loading = false;
  exportModalVisible = false;
  customProfitModalVisible = false;
  confirmRemoveModalVisible = false;
  invoiceTemplates = [];
  customerInvoicetemplate = 0;
  bigDealId = "";
  details: any = [];
  items: any = [];
  onExport = {
    templateid: 0,
    saveAsDefault: 0,
    showOnExport: 0,
    pricingid: "",
    advanced: {},
    customMessages: {},
    templateCustomDetails: {}
  };
  taxRateMessage = "";
  usedVariedTaxRate = false;
  invoiceStatusText = "";
  StatusPaidConfirmed = false;
  confirmStatusPaidVisible = false;
  hasPaidOrder = false;
  emailModalVisible = false;
  contactEmail = "";
  invoiceModelVisible = false;
  invOrderTotal = 0;
  overviewContent = "";
  mailSend = false;
  // isError = false;
  // message = "Item not exist or you have no access to this.";
  // redirectName = "Invoices";
  showPaymentZone = false;
  showcard = 1;
  paymentInfo: any = [];
  stripeToken = "";
  stripeLast4 = "";
  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  cvc = "";
  stripeError = 0;
  PaymentText = "";
  paymentConfirmID = "";
  orderPo = "";
  orderHasPaymentInfo = false;
  editPaymentInfoVisible = false;
  canDelete = false;
  canEdit = false;
  paymentStatus = "Paid";
  relatedOrder: any = {};
  invPaymentTotal = 0;
  paymentTotalAmount = true;
  uploading: any = false;
  hideCreditCard = false;
  hideCreditCardInfo = true;
  invoiceVoid = false;
  useCustomTax = 0;
  allocationList = [];
  allocationPercent = 0;
  allocationLoading = false;
  customProfit = false;
  paymentAmount = 0;
  paymentRefundedAmount = 0;
  refundModalVisible = false;
  businessLineSwitch = false;
  remainingBusinessLineComm: any = [];
  paying: string | boolean = false;
  savingCard: string | boolean = false;
  invoiceParams: any = {
    stripeCustomerId: "",
    stripeCardId: ""
  };
  removingCard = false;
  splitBySubOrderId: any = [];
  euCustomDataVisible = false;
  selectedSubOrder: any = {};
  isPayCheckPaid = 0;
  isEditNotes: boolean = false;
  invNotes: string = "";
  acctPriceType = 0;
  acctPriceTypeStr = "";
  customPriceTypeStr: string | undefined = undefined;

  customAlertModalVisible = false;
  alertsList: any = [];

  invoiceStatusOptions = [
    {
      ID: 0,
      TEXT: "Sent"
    },
    {
      ID: 1,
      TEXT: "Paid"
    },
    {
      ID: 2,
      TEXT: "Created"
    },
    {
      ID: 3,
      TEXT: "Partial Pay"
    }
  ];

  alertNotes = "";
  activeCustomAlert: any = [];
  allCustomAlert: any = [];
  showAllCustom = false;
  searchCustomAlertName = "";

  get filterCustomAlert() {
    const customAlerts = this.allCustomAlert.filter((alert) => alert.ALERTNAME.toLowerCase().includes(this.searchCustomAlertName.toLowerCase()));
    return customAlerts;
  }


  get invoiceStatusDesc () {
    const option = this.invoiceStatusOptions.find((opt) => opt.ID === this.details.IPAID);
    return option ? option.TEXT : '';
  }

  activeAlertDurations() {
    if (Array.isArray(this.alertsList)) {
      return this.alertsList.map(alert => {
        if (alert.ALERTNAME && alert.ALERTNAME !== "") {
          return alert.ALERTNAME;
        }
        return alert.DURATION;
      });
    }
    return [];
  }

  checkAllCustom() {
    this.showAllCustom = !this.showAllCustom;
  }

  async handleClick(alertID, alertCopy, currAlertId = 0, isActive) {
    if (alertID != "Create Custom Alert") {
      var alertName = "";
      if (alertID === 0) {
        alertName = alertCopy;
      }
      try {
        const matchingAlert = this.allCustomAlert.find(
          alert => alert.ALERTID === currAlertId
        );
        const alertParams = matchingAlert ? matchingAlert.PARAMS : {};

        const currAlert = this.alertsList.find(
          alert => alert.ALERTID === currAlertId
        ) || {};

        let response: any = {};
        if(!isActive || isActive === false){
          response = await ApiHelper.callApi("post", {
            FunctionName: "Add",
            controller: "Alerts",
            type: 3,
            id: this.$route.params.id,
            alertID: alertID,
            alertName: alertName,
            aID: this.details.AID,
            alertOverride: alertParams
          });
        }
        else {
          response['STATUS'] = 1;
        }

        if (response && response['STATUS'] === 1) {
          if (currAlertId && (!isActive || isActive === false)) {
            this.alertsList.push({
              ALERTNAME: alertName,
              ALERTID: response.ALERTGENERATEDKEY || currAlertId,
              PARAMS: alertParams,
              ISSAVEFORREUSE: true,
              DURATION: alertID.toString()
            });
            if (alertID != 1 && alertID != 7) {
              this.activeCustomAlert.push({
                ALERTNAME: alertName,
                ALERTID: response.ALERTGENERATEDKEY || currAlertId,
                PARAMS: alertParams,
                ISSAVEFORREUSE: true,
                DURATION: alertID.toString()
              });
              const index = this.allCustomAlert.findIndex(
                (item: any) => item.ALERTID === currAlertId
              );
              this.$delete(this.allCustomAlert, index);
            }
          }
          $(".alertBell").css(
            "filter",
            "invert(50%) sepia(90%) saturate(320%) hue-rotate(192deg)"
          );
          $(".menu.alertsList")
            .removeClass("overflow-visible")
            .animate({ height: 187 }, 200);
          $("#customAlert")
            .removeClass("overflow-visible")
            .animate({ height: 0 }, 200);
          $(".notesContainer .li.active").html(alertCopy);
          $(".notesContainer").removeClass("d-none");
          $(".notesContainer textarea").val(currAlert.CONTENT);
        }
      } catch (error) {
        console.error("There was an error making the request:", error);
      }
    }
  }

  async readAlert(alertID, duration = "") {
    let dataObj = {
      controller: "Alerts",
      FunctionName: "Update",
      alertId: alertID
    };
    if (duration.length) {
      dataObj["duration"] = alertID;
      dataObj["id"] = this.$route.params.id;
    }
    const response = await ApiHelper.callApi("post", dataObj);

    if (response && response.STATUS === 1) {
      if (duration.length) {
        this.alertsList = this.alertsList.filter(
          (item: any) => item.DURATION != alertID
        );
      } else {
        const index = this.activeCustomAlert.findIndex(
          (item: any) => item.ALERTID === alertID
        );
        const currItem = this.activeCustomAlert.find(
          (item: any) =>
            item.ALERTID === alertID &&
            ((item.ISSAVEFORREUSE || false) == true ||
              (item.ISSAVEFORREUSE || false) == "true")
        );
        if (currItem) {
          this.allCustomAlert.push(currItem);
        }
        this.$delete(this.activeCustomAlert, index);
        this.alertsList = this.alertsList.filter(
          (item: any) => item.ALERTID != alertID
        );
      }
      $(".menu.alertsList, #customAlert")
        .removeClass("overflow-visible")
        .animate({ height: 0 }, 200);
    }
  }

  async updateAlertNotes() {
    const notes = this.alertNotes;

    if (notes != undefined) {
      var self = this;
      getRouteData({
        controller: "Alerts",
        FunctionName: "Update",
        ObjID: this.$route.params.id,
        type: 3,
        Notes: notes
      }).then(function(response, statusText, jqXHR) {
        $(".alertsList").animate({ height: 0 }, 200, function() {
          $(".notesContainer").addClass("d-none");
          self.alertNotes = "";
        });
      });
    } else {
      $(".alertsList").animate({ height: 0 }, 200, function() {
        $(".notesContainer").addClass("d-none");
      });
    }
  }

  addCustomAlert(data) {
    this.activeCustomAlert.push(data);
    this.alertsList.push(data);
    this.allCustomAlert = this.allCustomAlert.filter(
      (item: any) => !this.activeAlertDurations().includes(item.ALERTNAME)
    );
  }

  gotoInvoiceEdit() {
    this.$router.push({
      name: "InvoiceEdit",
      params: { id: this.$route.params.id }
    });
  }

  async created() {
    await this.getInvoiceDetails();
    this.$forceUpdate();
  }

  getStripeDateInfo(date) {
    const response = {
      dateFormatted: '',
      expiredDateFormatted: '',
      isExpired: false,
    };
    if (date && moment(date).isValid()) {
      response.dateFormatted = moment(date).format("MM/DD/YY");
      const expiredDate = moment(date).add(7, 'days');
      response.expiredDateFormatted = expiredDate.format("MM/DD/YY");
      response.isExpired = moment() > expiredDate;
    }
    return response;
  }

  accessOrderLink = 1;
  async getInvoiceDetails() {
    var response: any = await invoiceRepo.findOneDetails(this.$route.params.id);
    if (Array.isArray(response.INVOICEITEMS)) {
      this.items = response.INVOICEITEMS.map(item => ({
        ...item,
        taxable: this.getItemTaxable({
          included: item.IPRICEINCLUDED,
          noTax: item.NOTAX,
          taxRate: item.TAXRATE
        })
      }));
    }
    this.splitBySubOrderId = response.splitBySubOrderId || [];
    for (const item of this.splitBySubOrderId) {
      for (const t of item.items) {
        t.taxable = this.getItemTaxable({
          included: t.IPRICEINCLUDED,
          noTax: t.NOTAX,
          taxRate: t.TAXRATE
        });
      }
    }

    this.details = response.INVOICEDETAILS;
    this.invNotes = this.removeHTMLTags(this.details.INOTES_FORMATTED);
    this.isPayCheckPaid = response.isPayCheckPaid || 0;
    this.accessOrderLink = response.ACCESS_ORDER_LINK || 0;
    this.paymentStatus = this.details.IPAID == 3 ? "Partial Pay" : "Paid";

    if (response.ALERT) {
      this.alertsList = response.ALERT;
    }
    this.activeCustomAlert = this.alertsList.filter(
      (alert: any) => alert.ALERTNAME && alert.ALERTNAME.length
    );
    if (response.CUSTOMALERTS) {
      this.allCustomAlert = response.CUSTOMALERTS.filter(
        (item: any) => !this.activeAlertDurations().includes(item.ALERTNAME) && item.TYPE === 3 // type 3 : Invoice
      );
    }
    // var hasAccess = true;
    // if (this.currentRole == "Customer" && this.details.IPAID == 2) {
    //   hasAccess = false;
    //   this.isError = true;
    // }
    // if (hasAccess) {
      this.loading = false;
      this.customerInvoicetemplate = response.AccountInvoiceTemplateId;
      this.invoiceTemplates = response.TEMPLATES;
      this.acctPriceType = response.acctPriceType || 0;
      this.acctPriceTypeStr = response.acctPriceTypeStr || "";
      if ("customPriceTypeStr" in response) {
        this.customPriceTypeStr = response.customPriceTypeStr;
      }
      // this.bigDealId = response.INVOICEDETAILS.SPECIALPRICINGID;
      if (
        typeof response.INVOICEDETAILS.CUSTOMDATACONTRACTNUMBER !=
          "undefined" &&
        response.INVOICEDETAILS.CUSTOMDATACONTRACTNUMBER != ""
      ) {
        this.bigDealId = response.INVOICEDETAILS.CUSTOMDATACONTRACTNUMBER;
      } else {
        this.bigDealId =
          typeof response.INVOICEDETAILS.NASPOCONTRACT != "undefined"
            ? response.INVOICEDETAILS.NASPOCONTRACT
            : "";
      }
      this.taxRateMessage = response.taxRateMessage || "";
      this.usedVariedTaxRate = response.usedVariedTaxRate || false;
      this.invoiceStatusText =
        this.details.IPAID == 3
          ? "Partial Pay"
          : this.details.IPAID == 2
          ? "Created"
          : this.details.IPAID == 0
          ? "Sent"
          : this.details.IPAID == 1
          ? "Paid"
          : "";
      this.customProfit =
        typeof this.details.CUSTOMPROFITTOTAL != "undefined" &&
        this.details.CUSTOMPROFITTOTAL != "" &&
        this.details.CUSTOMPROFITTOTAL !=
          (typeof this.details.IMARGIN !=
          "undefined"
            ? this.details.IMARGIN
            : 0)
          ? true
          : false;

      // more info for reseller
      if (this.currentRole == "Reseller") {
        if (typeof response.relatedOrder != "undefined") {
          this.relatedOrder = response.relatedOrder;
          const pStatus = response.relatedOrder.PSTATUS || 0;
          if (pStatus == 5) {
            this.hasPaidOrder = true;
          } else {
            this.hasPaidOrder = false;
          }
        }

        if (typeof response.relatedOrder != "undefined") {
          this.invOrderTotal = response.relatedOrder.POCUSTOMERTOTALPRICE;
          this.orderPo = response.relatedOrder.POID;
          let orderParams = response.relatedOrder.ORDERPARAMS || {};
          try {
            orderParams = JSON.parse(orderParams);
            this.contactEmail = orderParams.contactEmail || "";
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }

        if (
          typeof this.$route.query.invoicePaymentID === "number" &&
          this.$route.query.invoicePaymentID == 1
        ) {
          if (
            this.details.LATEPAYMENTMAIL == 1 &&
            this.mailSend == false &&
            this.details.IPAID != 1
          ) {
            this.emailModalVisible = true;
          }
        }

        this.paymentInfo = response.PaymentInfo || [];
        this.PaymentText = response.PaymentText || "";
        this.paymentConfirmID = response.PaymentConfirmID || "";
        this.paymentAmount = response.PaymentAmount || 0;
        this.paymentRefundedAmount = response.PaymentRefundedAmount || 0;
        if (
          !this.paymentInfo.length &&
          this.details.IPAID != InvoiceStatus.Paid
        ) {
          this.showcard = 3;
        }

        // load stripe
        await $.getScript("https://js.stripe.com/v2/", function(
          data,
          textStatus,
          jqxhr
        ) {}).fail(function(jqxhr, settings, exception) {
          console.log("Stripe load failes");
        });
        if (
          typeof response.StripePublicKey != "undefined" &&
          response.StripePublicKey != ""
        ) {
          Stripe.setPublishableKey(response.StripePublicKey);
        }

        // check if should show payment
        this.showPaymentZone = !!response.StripePublicKey || false;

        // check if related order has payment info
        if (typeof response.relatedOrder.confirmedPaymentInfo != "undefined") {
          this.orderHasPaymentInfo = true;
          // if(response.relatedOrder.PSTATUS == 5) {
          //   // order is paid
          //   this.showPaymentZone = false;
          // }
        }

        // detect if can delete this invoice
        this.canDelete = true;
        this.canEdit = true;
        if (
          typeof response.StripeProcessed != "undefined" &&
          response.StripeProcessed
        ) {
          // no allow delete if processed Stripe for this invoice
          this.canDelete = false;
          this.canEdit = false;
          this.showcard = 1;
        } else if (
          typeof response.relatedOrder.PSTATUS != "undefined" &&
          response.relatedOrder.PSTATUS == 5 &&
          this.orderHasPaymentInfo
        ) {
          // hide payment zone
          this.showPaymentZone = false;
        }

        // hide payment zone if marked this invoice as void
        if (this.details.IPAID == InvoiceStatus.Void) {
          this.showPaymentZone = false;
        }

        const invoiceParams = JSON.parse(this.details.INVOICEPARAMS || "{}");

        const stripeDateInfo = this.getStripeDateInfo(invoiceParams.stripeCardDate || '');
        this.invoiceParams = {
          ...invoiceParams,
          stripeCardDate: stripeDateInfo.dateFormatted,
          stripeExpiredDateFormatted: stripeDateInfo.expiredDateFormatted,
          stripeCardExpired: stripeDateInfo.isExpired
        };

        this.invoiceParams.stripeCustomerId =
          invoiceParams.stripeCustomerId || "";
        this.invoiceParams.stripeCardId = invoiceParams.stripeCardId || "";
      }

      // detect if using a custom tax?
      var hasTaxable = false;
      for (const item in this.items) {
        if (this.items[item].taxable) {
          hasTaxable = true;
          break;
        }
      }
      if (!hasTaxable && this.details.CUSTOMERTAX) {
        this.useCustomTax = 1;
      }
    // }
    if (
      typeof this.$route.query.showProfitModal != "undefined" &&
      this.$route.query.showProfitModal
    ) {
      this.showOverride();
    }
  }
  listPageRedirection() {
    this.$router.push({ name: "Invoices" });
  }
  async invoiceDetailsHtml(): Promise<string | false> {
    try {
      // const quoteDetails = await quoteRepo.findOneDetails(this.$route.params.id)
      const invoiceDetails = await invoiceRepo.findOneDetails(
        this.$route.params.id,
        {
          ...this.onExport,
          doExport: 1
        }
      );

      const tempPosition1 = invoiceDetails.TemplateSetting.PartialPosition1;
      const tempPosition2 = invoiceDetails.TemplateSetting.PartialPosition2;
      if (tempPosition1.length > 0) {
        tempPosition1.forEach(item => {
          var invoiceDetails_position1 = require("../templates/invoices/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(
            item.partialname,
            invoiceDetails_position1
          );
        });
      }
      if (tempPosition2.length > 0) {
        tempPosition2.forEach(item => {
          var invoiceDetails_position2 = require("../templates/invoices/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(
            item.partialname,
            invoiceDetails_position2
          );
        });
      }

      if (invoiceDetails.TemplateSetting.TemplateName != "") {
        const mainTemplate = invoiceDetails.TemplateSetting.TemplateName.split(
          "/"
        );
        var template = require("../templates/invoices/" +
          mainTemplate[mainTemplate.length - 1]);
        const html = template(invoiceDetails);
        return html;
      } else {
        return "";
      }
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async htmlDownLoadFn() {
    const html = await this.invoiceDetailsHtml();
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      if (!html) return;
      var fileNameExport =
        "Invoice_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }
  async print() {
    const html = await this.invoiceDetailsHtml();
    if (!html) return;

    printHtml(html);
  }
  async exportCSV() {
    let fileUrl = "";
    try {
      fileUrl = await invoiceRepo.exportDetailsCSVFileUrl(
        this.$route.params.id,
        this.onExport
      );
    } catch (err) {
      notifier.alert(err.message);
    }
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      downloadFileUrl(fileUrl);
    };
  }
  async exportPDF() {
    let fileUrl = "";
    try {
      fileUrl = await invoiceRepo.exportDetailsPDFFileUrl(
        this.$route.params.id,
        this.onExport
      );
    } catch (err) {
      notifier.alert(err.message);
    }
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async performExport(
    formats: ExportFormat[],
    templateId,
    updateDefault,
    showOnExport,
    pricingid,
    exportpo?,
    azTempname = "",
    advanced?,
    customMessages?,
    exportOrderDetail?,
    reportingOrderDetail?,
    exportDirectPaycheckDetail?,
    CustomDistyName?,
    CustomDistyAddr1?,
    CustomDistyAddr2?,
    CustomDistyCity?,
    CustomDistyState?,
    CustomDistyZip?,
    varSourceName?,
    exportInvoiceAgingReport?,
    exportLedgerAgingReport?,
    exportInvoiceAgingDate?,
    exportView?,
    hardwareCSVExport?,
    activeTab?,
    isDistyTemplate?,
    templateCustomDetails = {}
  ) {
    // this.loading = true;
    this.onExport = {
      templateid: templateId,
      saveAsDefault: updateDefault ? 1 : 0,
      showOnExport: showOnExport ? 1 : 0,
      pricingid: pricingid,
      advanced: advanced,
      customMessages: customMessages,
      templateCustomDetails: templateCustomDetails
    };
    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.exportCSV());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.exportPDF());
    }

    exportFns.forEach(fn => fn());
    // this.getInvoiceDetails();
    var response = await invoiceRepo.findOneDetails(this.$route.params.id);
    this.customerInvoicetemplate = response.AccountInvoiceTemplateId;
    this.loading = false;
  }

  async removeSelected() {
    var refModal: any = this.$refs.confirmRemoveModal;
    refModal.deleting = true;
    try {
      const successCount = await invoiceRepo.removeByIds(
        [this.$route.params.id],
        false,
        [],
        this.details.AID
      );
      if (successCount > 0) {
        this.$router.push({ name: "Invoices" });
      }
    } catch (err) {
      refModal.deleting = "error";
    } finally {
      refModal.deleting = false;
      this.confirmRemoveModalVisible = false;
    }
  }

  async sendToQuickbooks() {
    const path =
      microserviceQBOURL +
      "/invoices/send_to_quickbooks?id=" +
      this.$route.params.id;
    var win;
    var checkConnect;
    var parameters = "location=1,width=800,height=650";
    parameters +=
      ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;

    // Launch Popup
    win = window.open(path, "connectPopup", parameters);
  }

  getItemTaxable(item) {
    let taxable = true;
    if (typeof item.taxable != "undefined" && item.taxable) {
      taxable = item.taxable;
    } else if (typeof item.taxRate != "undefined" && item.taxRate == 0) {
      taxable = false;
    }

    return taxable;
  }

  async changeStatus(status, confirmedVals: any = {}) {
    if (this.details.IPAID == status) {
      return;
    }

    if (status == 1 && !this.StatusPaidConfirmed) {
      this.confirmStatusPaidVisible = true;
    } else {
      try {
        this.loading = true;
        const response = await invoiceRepo.updateInvoiceStatus(
          this.$route.params.id,
          status,
          this.details.PURCHASEID || 0,
          confirmedVals.profitTotal || 0,
          this.details.AID,
          confirmedVals
        );
        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS && response.data.STATUS == 1) {
          this.getInvoiceDetails();
          notifier.success("Status Updated Successfully");
        }
      } catch (err) {
        // console.log(err.message);
      } finally {
        this.loading = false;
        this.confirmStatusPaidVisible = false;
        this.StatusPaidConfirmed = false;
      }
    }
  }

  invoicePaidConfirm(confirmedVals) {
    this.StatusPaidConfirmed = true;
    this.changeStatus(1, confirmedVals);
  }

  async viewInvoice() {
    this.loading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Invoices",
      FunctionName: "ExportView",
      invoiceID: this.$route.params.id,
      ExportType: "HTML",
      Content: "Detailed"
    });
    const invoiceDetails = response.data;
    invoiceDetails.ispreview = 1;
    if (invoiceDetails.TemplateSetting.TemplateName != "") {
      const mainTemplate = invoiceDetails.TemplateSetting.TemplateName.split(
        "/"
      );
      var templateInv = require("../templates/invoices/" +
        mainTemplate[mainTemplate.length - 1]);
    } else {
      //Use default invoice template if no define
      // eslint-disable-next-line no-redeclare
      var templateInv = require("../templates/invoices/invoiceDetails.handlebars");
    }
    var html = templateInv(invoiceDetails);
    this.overviewContent = html;
    this.invoiceModelVisible = true;
    this.loading = false;
  }

  async validateCardName() {
    if (htmlCheck(this.cardName)) {
      this.cardName = htmlParse(this.cardName);
      notifier.alert("HTML content found. This content has been removed!");
    }
  }

  cardNumberChange() {
    // need to verify card again in this case
    this.stripeToken = "";
  }

  async deletePayment() {
    // this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "invoices",
        FunctionName: "updatePayment",
        aID: this.details.AID,
        invoiceID: this.$route.params.id,
        isUpdate: false
      });
      // console.log('resposese',response)
      if (response.data.STATUS == 1) {
        this.getInvoiceDetails();
        notifier.success(response.data.STATUSMESSAGE);
        this.resetstripeform();
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  async stripeTest() {
    var $form = $("#posttodetailpop");
    var $this = this;
    var stripeResponseHandler = function(status, response) {
      //Stripe token failure...
      if (response.error) {
        // console.log("response error",response)
        var msg = "card name";
        if (response.error.param == "number") {
          $this.stripeError = 2;
          msg = "card number";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
          msg = "month";
        } else if (response.error.param == "exp_year") {
          $this.stripeError = 4;
          msg = "year";
        } else if (response.error.param == "cvc") {
          $this.stripeError = 5;
          msg = "cvc";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
        } else {
          $this.stripeError = 1;
        }
        notifier.alert("Verification failed due to invalid " + msg);
      } else {
        notifier.success("Card details verified successfully..");
        $this.stripeToken = response.id;
        $this.stripeLast4 = response.card.last4;
        $this.stripeError = 0;
      }
    };
    Stripe.card.createToken($form, stripeResponseHandler);
  }

  resetstripeform() {
    this.stripeToken = "";
    this.stripeLast4 = "";
    this.cardName = "";
    this.cardNumber = "";
    this.expMoth = "";
    this.expyear = "";
    this.cvc = "";
  }

  // async submitPayment() {
  //   // this.loading = true;
  //   this.uploading = true;
  //   try {
  //     const data = {
  //       controller: "Invoices",
  //       FunctionName: "updatePayment",
  //       token: this.stripeToken,
  //       last4: this.stripeLast4,
  //       aID: this.details.AID,
  //       cardName: this.cardName,
  //       InvoiceID: this.$route.params.id,
  //       isUpdate: true,
  //       invPaymentTotal: this.details.STRIPEDUEBALANCE,
  //     };
  //     const response = await axios.post(dataURL + "?ReturnType=JSON", data);
  //     if (response.data.STATUS == 1) {
  //       await this.getInvoiceDetails();
  //       this.showcard = 1;
  //       this.resetstripeform();
  //       notifier.success(response.data.STATUSMESSAGE);
  //     } else {
  //       notifier.alert(response.data.STATUSMESSAGE);
  //     }
  //   } catch (err) {
  //     console.log(err.message);
  //   } finally {
  //     // this.loading = false;
  //     // this.cardupdate = 0;
  //     this.uploading = false;
  //   }
  // }

  async confirmEditPaymentInfo(confirmedVals) {
    // if((this.details.PURCHASEID || 0) > 0 && this.orderHasPaymentInfo) {
    if (this.orderHasPaymentInfo) {
      try {
        // this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Orders",
          FunctionName: "UpdatePaymentInfo",
          purchaseID: this.details.PURCHASEID || 0,
          invoiceID: this.$route.params.id,
          profit: confirmedVals.profitTotal,
          paymentAmount: confirmedVals.paymentAmount,
          paymentDate: confirmedVals.paymentDate,
          paymentType: confirmedVals.paymentType,
          paymentReference: confirmedVals.paymentReference,
          taxAmount: confirmedVals.taxAmount,
          isPartial: confirmedVals.isPartial,
          mdInvoiceStatus: 1,
          invoicePartialAmount: confirmedVals.paymentAmount,
          partialAmountEdit: 1,
          paymentID: confirmedVals.paymentID,
          zeroPayment: confirmedVals.zeroPayment
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
          notifier.success(response.data.STATUSMESSAGE);
          this.getInvoiceDetails();
          // if(response.data.UPDATEPARTIALPAYMENT)
          //   this.paymentStatus = "Partial Pay";
          // else
          //   this.paymentStatus = "Paid";
        } else if (response.data.STATUSMESSAGE) {
          notifier.alert(response.data.STATUSMESSAGE);
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        // this.loading = false;
        this.editPaymentInfoVisible = false;
      }
    }
  }

  async invoicePaidRemove() {
    try {
      var purchaseID =
        typeof (this.details.PURCHASEID != "undefined") &&
        this.details.PURCHASEID != ""
          ? this.details.PURCHASEID
          : 0;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "UpdatePaymentInfo",
        purchaseID: purchaseID,
        invoiceID: this.$route.params.id,
        partialAmountEdit: 1,
        invoiceReverted: 1
      });
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
        await this.getInvoiceDetails();
        this.hasPaidOrder = false;
        notifier.success(response.data.STATUSMESSAGE);
      } else if (response.data.STATUSMESSAGE) {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      this.editPaymentInfoVisible = false;
    }
  }

  invoicePaymentAmount() {
    const oPaymentAmount = Math.abs(this.details.STRIPEDUEBALANCE);
    const paymentGrantTotal = this.details.GRANDTOTAL;
    if (
      oPaymentAmount > paymentGrantTotal ||
      isNaN(oPaymentAmount) ||
      oPaymentAmount == 0
    ) {
      $(".paymentTotal #orderTotal").addClass("paymentTotalError");
      this.paymentTotalAmount = false;
    } else {
      $(".paymentTotal #orderTotal").removeClass("paymentTotalError");
      this.paymentTotalAmount = true;
      this.details.STRIPEDUEBALANCE = oPaymentAmount;
    }
  }

  async invoiceCommentInfo(invoiceComment) {
    var refModal: any = this.$refs.invoiceVoidReasonModal;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Invoices",
        FunctionName: "InvoiceVoid",
        InvoiceID: this.$route.params.id,
        voidReason: invoiceComment.voidReason,
        purchaseID: this.details.PURCHASEID || 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
        notifier.success(response.data.STATUSMESSAGE);
        this.getInvoiceDetails();
      } else if (response.data.STATUSMESSAGE) {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      refModal.deleting = false;
      this.invoiceVoid = false;
    }
  }

  async confirmProfitTotal(currentProfit) {
    var refModal: any = this.$refs.ConfirmProfitTotalRef;
    refModal.saving = true;
    if (
      currentProfit == "" &&
      typeof this.relatedOrder.confirmedPaymentInfo.PROFITTOTAL != "undefined"
    ) {
      currentProfit = this.relatedOrder.confirmedPaymentInfo.PROFITTOTAL;
    }
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Invoices",
        FunctionName: "InvoiceCustomProfitUpdate",
        InvoiceID: this.$route.params.id,
        profit: currentProfit,
        customProfitUpdate: true
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
        notifier.success(response.data.STATUSMESSAGE);
        this.customProfitModalVisible = false;
        await this.getInvoiceDetails();
      }
    } catch (err) {
      // console.log(err);
    } finally {
      refModal.saving = false;
      this.customProfitModalVisible = false;
    }
  }

  removeHTMLTags(text) {
    return text.replace(/<\/?[^>]+(>|$)/g, " ");
  }

  async showOverride() {
    try {
      this.allocationLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Invoices",
        FunctionName: "InvoiceCommission",
        invoiceId: this.$route.params.id
      });

      if (response.data.STATUS == 1) {
        this.allocationList =
          typeof response.data.ALLOCATIONARR != "undefined"
            ? response.data.ALLOCATIONARR
            : [];
        this.allocationPercent =
          typeof response.data.TOTALPERCENT != "undefined"
            ? response.data.TOTALPERCENT
            : 0;
        this.businessLineSwitch =
          typeof response.data.BUSINESSLINESWITCH != "undefined"
            ? response.data.BUSINESSLINESWITCH
            : false;
        this.remainingBusinessLineComm =
          response.data.REMAININGBUSINESSLINECOMM;
        this.customProfitModalVisible = true;
      }

      this.allocationLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customProfitModalVisible = false;
    }
  }

  get canRefund() {
    if (this.paymentAmount - this.paymentRefundedAmount > 0) {
      return true;
    }
    return false;
  }

  saveCardData() {
    if (
      this.cardName == "" ||
      this.cardNumber == "" ||
      this.expMoth == "" ||
      this.expyear == "" ||
      this.cvc == ""
    ) {
      return;
    }

    if (this.cvc.length < 3) {
      this.stripeError = 5;
    } else {
      this.stripeError = 0;
    }

    this.savingCard = true;
    const $this = this;
    Stripe.card.createToken($("#posttodetailpop"), async (status, response) => {
      // Stripe token failure...
      if (response.error) {
        var msg = "card name";
        if (response.error.param == "number") {
          $this.stripeError = 2;
          msg = "card number";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
          msg = "month";
        } else if (response.error.param == "exp_year") {
          $this.stripeError = 4;
          msg = "year";
        } else if (response.error.param == "cvc") {
          $this.stripeError = 5;
          msg = "cvc";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
        } else {
          $this.stripeError = 1;
        }
        notifier.alert("Verification failed due to invalid " + msg);
      } else {
        // $this.stripeToken = response.id;
        // $this.stripeLast4 = response.card.last4;
        $this.stripeError = 0;
      }

      if ($this.stripeError == 0) {
        const stripeToken = response.id;
        const stripeLast4 = response.card.last4;
        try {
          // save card data
          const result = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Orders",
            FunctionName: "SaveCardData",
            type: "invoice",
            invoiceId: this.$route.params.id,
            cardName: this.cardName,
            cardNumber: this.cardNumber,
            expMoth: this.expMoth,
            expYear: this.expyear,
            cvc: this.cvc,
            stripeToken,
            stripeLast4
          });
          if (result.data.STATUS == 1) {
            this.savingCard = false;
            this.invoiceParams.stripeCustomerId =
              result.data.stripeCustomerId || "";
            this.invoiceParams.stripeCardId = result.data.stripeCardId || "";
            notifier.success("Saved successfully!");
          } else {
            this.savingCard = "error";
            const message = result.data.MESSAGE || "";
            if (message) {
              notifier.alert(message);
            }
          }
        } catch (error) {
          console.log(error);
          this.savingCard = "error";
        }
      } else {
        this.savingCard = "error";
      }
    });
  }

  chargePayment() {
    if (
      this.cardName == "" ||
      this.cardNumber == "" ||
      this.expMoth == "" ||
      this.expyear == "" ||
      this.cvc == ""
    ) {
      return;
    }

    if (this.cvc.length < 3) {
      this.stripeError = 5;
    } else {
      this.stripeError = 0;
    }
    // if (this.paymentTotalAmount == false) {
    //   return;
    // }
    this.stripeToken = "";
    this.paying = true;
    const $this = this;
    Stripe.card.createToken($("#posttodetailpop"), async (status, response) => {
      // Stripe token failure...
      if (response.error) {
        var msg = "card name";
        if (response.error.param == "number") {
          $this.stripeError = 2;
          msg = "card number";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
          msg = "month";
        } else if (response.error.param == "exp_year") {
          $this.stripeError = 4;
          msg = "year";
        } else if (response.error.param == "cvc") {
          $this.stripeError = 5;
          msg = "cvc";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
        } else {
          $this.stripeError = 1;
        }
        notifier.alert("Verification failed due to invalid " + msg);
      } else {
        $this.stripeToken = response.id;
        $this.stripeLast4 = response.card.last4;
        $this.stripeError = 0;
      }

      if ($this.stripeToken != "" && $this.stripeError == 0) {
        try {
          await this.updatePayment();
          this.paying = false;
        } catch (error) {
          console.log(error);
          this.paying = "error";
        }
      } else {
        this.paying = "error";
      }
    });
  }

  get disbleSubmitCard() {
    if (
      this.cardName == "" ||
      this.cardNumber == "" ||
      this.expMoth == "" ||
      this.expyear == "" ||
      this.cvc == ""
    ) {
      return true;
    }

    return false;
  }

  async updatePayment() {
    if (this.stripeError != 0) return;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      // controller: "Orders",
      // FunctionName: "updatePayment",
      // token: this.stripeToken,
      // last4: this.stripeLast4,
      // aID: this.details.ORDERDETAILS.AID,
      // cardName: this.cardName,
      // purchaseID: this.$route.params.id,
      // isUpdate: true,
      // isNew: this.cardupdate,
      // orderPaymentTotal: this.details.DUEBALANCE,
      // paymentType : "Stripe"

      controller: "Invoices",
      FunctionName: "updatePayment",
      token: this.stripeToken,
      last4: this.stripeLast4,
      aID: this.details.AID,
      cardName: this.cardName,
      InvoiceID: this.$route.params.id,
      isUpdate: true,
      invPaymentTotal: this.details.STRIPEDUEBALANCE
    });
    if (response.data.STATUS == 1) {
      await this.getInvoiceDetails();
      this.showcard = 1;
      this.resetstripeform();
      notifier.success(response.data.STATUSMESSAGE);
    } else {
      notifier.alert(response.data.STATUSMESSAGE);
    }
  }

  changeCard() {
    this.showcard = 3;
    // this.cardupdate = 1;
    this.hideCreditCard = true;
    this.hideCreditCardInfo = false;
  }

  hasInfoToCharge() {
    // const stripeToken = this.details.orderParams.stripeToken || '';
    const stripeCardId = this.invoiceParams.stripeCardId || "";
    const stripeCustomerId = this.invoiceParams.stripeCustomerId || "";
    if (stripeCardId != "" && stripeCustomerId != "") {
      return true;
    }

    return false;
  }

  async deleteUncapturePayment() {
    try {
      this.removingCard = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "RemoveUncapturedPayment",
        type: "invoice",
        aId: this.details.AID,
        purchaseId: this.details.PURCHASEID,
        invoiceId: this.$route.params.id,
        stripeCustomerId: this.invoiceParams.stripeCustomerId,
        stripeCardId: this.invoiceParams.stripeCardId
      });
      if (response.data.STATUS == 1) {
        this.getInvoiceDetails();
        this.resetstripeform();
        this.showcard = 3;
        this.hideCreditCard = false;
        this.hideCreditCardInfo = true;
        this.invoiceParams.stripeCustomerId = "";
        this.invoiceParams.stripeCardId = "";
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.removingCard = false;
    }
  }

  cancelDeletePayment() {
    // cancel delete a stripe card payment
    if (this.showcard == 2) {
      this.showcard = 1;
    } else if (this.showcard == 4) {
      // if cancel delete an uncapture payment, back to "submit payment" view
      this.showcard = 3;
    }
  }

  validateBeforeCapture() {
    if (!this.hasInfoToCharge()) return;
    // const stripeCardId = this.invoiceParams.stripeCardId || '';
    // const stripeCustomerId = this.invoiceParams.stripeCustomerId || '';

    // const orderGrandTotal = this.details.GRANDTOTAL || 0;
    // if(this.uncapturedAmount != 0 && orderGrandTotal != 0 && this.uncapturedAmount != orderGrandTotal) {
    //   // show warning if charge amount is different with order grand total
    //   this.confirmChargeVisible = true;
    //   return;
    // }

    // // continue to capture
    // const chargeByCustomerId = !stripeToken && (stripeCustomerId && stripeCardId) ? true : false;
    // this.capturePayment(stripeToken, chargeByCustomerId);
    this.capturePayment("", true);
  }

  async capturePayment(stripeToken = "", chargeByCustomerId = false) {
    // if(!stripeToken) return false;
    if (!this.hasInfoToCharge()) return;

    const chargeTotal = this.details.STRIPEDUEBALANCE || 0;
    if (chargeTotal == 0) return;

    try {
      this.paying = true;
      // let chargeTotal = this.uncapturedAmount || 0;
      // if(chargeTotal == 0 && chargeByCustomerId) {
      //   // if charge by stripe customer/card, use a due balance of this order
      //   chargeTotal = this.details.DUEBALANCE;
      // }

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        // controller: "Orders",
        // FunctionName: "updatePayment",
        // token: stripeToken,
        // // last4: this.stripeLast4,
        // aID: this.details.ORDERDETAILS.AID,
        // // cardName: this.cardName,
        // purchaseID: this.$route.params.id,
        // isUpdate: true,
        // // isNew: this.cardupdate,
        // orderPaymentTotal: chargeTotal,
        // paymentType: "Stripe",
        // capturePayment: true,
        // stripeCustomerId: this.details.orderParams.stripeCustomerId || "",
        // stripeCardId: this.details.orderParams.stripeCardId || "",
        // chargeByCustomerId,
        // uncapturedAmount: chargeTotal
        controller: "Invoices",
        FunctionName: "updatePayment",
        token: stripeToken,
        // last4: this.stripeLast4,
        aID: this.details.AID,
        // cardName: this.cardName,
        InvoiceID: this.$route.params.id,
        isUpdate: true,
        invPaymentTotal: chargeTotal,
        capturePayment: true,
        stripeCustomerId: this.invoiceParams.stripeCustomerId || "",
        stripeCardId: this.invoiceParams.stripeCardId || ""
        // chargeByCustomerId,
        // uncapturedAmount: chargeTotal
      });
      if (response.data.STATUS == 1) {
        await this.getInvoiceDetails();
        this.showcard = 1;
        this.resetstripeform();
        this.paying = false;
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        this.paying = "error";
        notifier.alert(response.data.STATUSMESSAGE);

        // the charge maybe is cancelled, notify to charge by customer info
        // const errorCode = response.data.errorCode || "";
        // if(errorCode != "charge_already_refunded") {
        //   notifier.alert(response.data.STATUSMESSAGE);
        // }else if(this.details.orderParams.stripeCustomerId) {
        //   // if admin still want to charge by customer/card id
        //   this.confirmChargeByCustomerIdText = `<div>${response.data.STATUSMESSAGE}</div>
        //     Do you want to charge this order <strong>${this.uncapturedAmountFormatted}</strong> for customer <strong>${this.details.orderParams.stripeCustomerId}</strong>?
        //   `;
        //   this.confirmChargeByCustomerIdVisible = true;
        // }

        return false;
      }
      // this.confirmChargeVisible = false;
    } catch (err) {
      this.paying = "error";
      console.log(err);
    } finally {
      // this.paying = false;
      // this.cardupdate = 0;
    }

    // this.submitPaymentBtnEnabled = true;
    // this.verifiedBtnEnabled = false;

    return true;
  }

  lastGroupItem(item, index, items: any = []) {
    if (!item || !item.IPRICEINCLUDED) {
      return false;
    }

    // check if next item is a base, then this is last item
    const nextItem = !this.isMultiEndUsers
      ? this.items[index + 1]
      : items[index + 1];
    if (nextItem && !nextItem.IPRICEINCLUDED) {
      return true;
    }

    return false;
  }

  get isMultiEndUsers() {
    // check data of splitBySubOrderId
    if (
      this.splitBySubOrderId.length >= 1 &&
      this.splitBySubOrderId[0].subOrderId > 0
    ) {
      return true;
    }

    return false;
  }

  showEUCustomData(ss) {
    this.selectedSubOrder = ss;
    this.euCustomDataVisible = true;
  }

  async saveInvNotes() {
    if (this.invNotes != this.removeHTMLTags(this.details.INOTES_FORMATTED)) {
      const response = await ApiHelper.callApi("post", {
        controller: "Invoices",
        FunctionName: "UpdateInvoice",
        InvoiceID: this.$route.params.id,
        invNotes: this.invNotes,
        action: "updateInvoiceNotes"
      });

      if (response.STATUS == 1) {
        this.details.INOTES_FORMATTED = this.invNotes;
        notifier.success(response.STATUSMESSAGE);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    }
  }

  hideInvNotesBox() {
    this.isEditNotes = false;
  }
}
