var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"employeePlan-Details",attrs:{"id":"container"}},[_c('add-header'),_c('div',{staticClass:"up d-flex",attrs:{"id":"page-header"}},[_c('div',{staticClass:"d-flex w-35"},[_c('div',[_c('span',{staticClass:"page-title",attrs:{"id":"empPreviousIcon"}},[_c('span',{attrs:{"id":"previousIcon"},on:{"click":_vm.listPageRedirection}})]),_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"circle plan-color"},[_c('p',{staticClass:"circle-inner"},[_vm._v(" "+_vm._s(_vm.getInitials(_vm.details.EMPLOYEENAME))+" ")])]),_c('span',{staticClass:"page-title pl-0 employeeName",attrs:{"title":_vm.details.EMPLOYEENAME}},[_vm._v(_vm._s(_vm.details.EMPLOYEENAME))]),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('router-link',{attrs:{"to":{
              name: 'EmployeePlanEdit',
              params: {
                id: _vm.details.COMPUUID
              }
            }}},[_c('span',{staticClass:"list-count left cursor-pointer"},[_c('span',{staticClass:"searchFound text-grey"},[_vm._v("Edit Plan")])])]):_vm._e(),_c('span',{staticClass:"list-count left cursor-pointer pl-3 simMode-container"},[_c('span',{staticClass:"searchFound text-grey",class:{
                'active' : _vm.activateSimMode
              },on:{"click":function($event){return _vm.simMode()}}},[_vm._v("Sim Mode")])]),_c('img',{staticClass:"simMode-dropdown cursor-pointer pl-2 pb-2",attrs:{"src":require("@/assets/images/icon_dropdown_rounded.png"),"height":"12","width":"12"},on:{"click":function () {
              _vm.showSimModeModal = true;
            }}})],1),(_vm.details.OTEYEAR)?_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"oteYear"},[_vm._v(_vm._s(_vm.details.OTEYEAR)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"icon ml-3",class:{
                'green' : _vm.details.CSTATUS === 1,
                'red' : _vm.details.CSTATUS !== 1
              }}),_c('div',{staticClass:"ml-2"},[(_vm.details.CSTATUS === 1)?[_vm._v(" Active ")]:_vm._e(),(_vm.details.CSTATUS !== 1)?[_vm._v(" Depreciated ")]:_vm._e()],2)])]):_vm._e()])]),_c('div',{staticClass:"d-flex w-65"},[_c('div',{staticClass:"pr-3 w-31"},[_c('div',{staticClass:"ote-container"},[_c('div',{staticClass:"ote-container-details"},[_c('div',{staticClass:"d-flex justify-content-between",class:{
                'cursor-pointer' : _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)
              },on:{"click":function () {
                if (_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                  _vm.showCommTargetModal = true;
                }
              }}},[_c('span',{staticClass:"ote-price price-textOverflow",attrs:{"title":_vm.details.OTEBASE_FORMATTED}},[_vm._v(" "+_vm._s(_vm.details.OTEBASE_FORMATTED)+" ")]),_c('span',{staticClass:"ote-title"},[_vm._v(_vm._s(_vm.details.OTEYEAR)+" ote base")])]),_c('div',{staticClass:"ote-line"})]),_c('div',{staticClass:"ote-container-details"},[_c('div',{staticClass:"d-flex justify-content-between",class:{
                'cursor-pointer' : _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)
              },on:{"click":function () {
                if (_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                  _vm.showCommTargetModal = true;
                }
              }}},[_c('span',{staticClass:"ote-price price-textOverflow",attrs:{"title":_vm.details.OTECOMM_FORMATTED}},[_vm._v(" "+_vm._s(_vm.details.OTECOMM_FORMATTED)+" ")]),_c('span',{staticClass:"ote-title"},[_vm._v(_vm._s(_vm.details.OTEYEAR)+" ote Comm")])]),_c('div',{staticClass:"ote-line"})]),_c('div',{staticClass:"ote-container-details"},[_c('div',{staticClass:"d-flex justify-content-between",class:{
                'cursor-pointer' : _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)
              },on:{"click":function () {
                if (_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                  _vm.showCommTargetModal = true;
                }
              }}},[_c('span',{staticClass:"ote-price price-textOverflow",attrs:{"title":_vm.details.TOTALOTE_FORMATTED}},[_vm._v(" "+_vm._s(_vm.details.TOTALOTE_FORMATTED)+" ")]),_c('span',{staticClass:"ote-title"},[_vm._v(_vm._s(_vm.details.OTEYEAR)+" ote Total")])])])])]),_c('div',{staticClass:"pr-3 w-35",on:{"mouseleave":function () {
          _vm.showTargetProfitTooltip = false;
        }}},[_c('div',{staticClass:"target-profit-container",on:{"mouseenter":function () {
            _vm.showTargetProfitTooltip = true;
          }}},[_c('div',{staticClass:"target-profit-container-details"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"target-price price-textOverflow",class:{
                  'cursor-pointer target-price-dotted' : _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)
                },attrs:{"title":_vm.details.COMP_TARGET_FORMATTED},on:{"click":function () {
                  if (_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                    _vm.showCommTargetModal = true;
                  }
                }}},[_vm._v(_vm._s(_vm.details.COMP_TARGET_FORMATTED))]),_c('span',{staticClass:"target-title"},[_vm._v(_vm._s(_vm.details.OTEYEAR)+" Target Profit")])]),_c('div',{staticClass:"border-line"})]),_c('div',{staticClass:"target-profit-container-details"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"target-price price-textOverflow",class:{
                  'cursor-pointer target-price-dotted' : _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)
                },attrs:{"title":_vm.activateSimMode ? _vm.realizedProfitSimModeTotal : _vm.realizedProfitTotal},on:{"click":function () {
                  if (_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                    _vm.showCommTargetModal = true;
                  }
                }}},[(_vm.activateSimMode)?[_vm._v(" "+_vm._s(_vm.realizedProfitSimModeTotal)+" ")]:[_vm._v(" "+_vm._s(_vm.realizedProfitTotal)+" ")]],2),_c('span',{staticClass:"target-title"},[_vm._v(_vm._s(_vm.details.OTEYEAR)+" Realized Profit")])]),_c('div',{staticClass:"border-line"})]),_c('div',{staticClass:"target-profit-container-details"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"target-price price-textOverflow",class:{
                  'cursor-pointer target-price-dotted' : _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin),
                  'green' : (!_vm.activateSimMode && _vm.remainingProfitTotalAmount < 0) || (_vm.activateSimMode && _vm.remainingProfitSimModeTotalAmount < 0)
                },attrs:{"title":_vm.activateSimMode ? _vm.remainingProfitSimModeTotal : _vm.remainingProfitTotal},on:{"click":function () {
                  if (_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                    _vm.showCommTargetModal = true;
                  }
                }}},[(_vm.activateSimMode)?[_vm._v(" "+_vm._s(_vm.remainingProfitSimModeTotal)+" ")]:[_vm._v(" "+_vm._s(_vm.remainingProfitTotal)+" ")]],2),_c('span',{staticClass:"target-title"},[_vm._v("Remaining Profit")])])])]),(_vm.showTargetProfitTooltip)?_c('TargetProfitTooltip',{attrs:{"action":"targetProfit","empPlanAccountData":_vm.empPlanAccountData,"data":_vm.details}}):_vm._e()],1),_c('div',{staticClass:"w-33",on:{"mouseleave":function () {
          _vm.estUnrealizedCommInfoTooltipVisibleIndex = false;
        }}},[_c('div',{staticClass:"comm-container"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"comm-price price-textOverflow",attrs:{"title":_vm.activateSimMode ? _vm.commissionSimModeTotal : _vm.commissionTotal}},[(_vm.activateSimMode)?[_vm._v(" "+_vm._s(_vm.commissionSimModeTotal)+" ")]:[_vm._v(" "+_vm._s(_vm.commissionTotal)+" ")]],2),_c('span',{staticClass:"comm-title"},[_vm._v("Commission "+_vm._s(_vm.details.OTEYEAR))])]),_c('div',{staticClass:"d-flex justify-content-between pt-3"},[_c('span',{staticClass:"accelerators-price price-textOverflow",attrs:{"title":_vm.activateSimMode ? _vm.acceleratorSimModeTotal : _vm.acceleratorTotal}},[(_vm.activateSimMode)?[_vm._v(" "+_vm._s(_vm.acceleratorSimModeTotal)+" ")]:[_vm._v(" "+_vm._s(_vm.acceleratorTotal)+" ")]],2),_c('span',{staticClass:"d-flex justify-content-between align-items-center"},[(_vm.acceleratorData.length)?_c('span',{staticClass:"accelerators-total-cnt mr-3"},[_vm._v(" "+_vm._s(_vm.acceleratorData.length)+" ")]):_vm._e(),_c('span',{staticClass:"accelerators-title",class:{
                  'cursor-pointer accelerators-title-dotted' : _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)
                },on:{"click":function () {
                  if (_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                    _vm.showAcceleratorsModal = true;
                  }
                }}},[_vm._v("Accelerators")])])]),_c('div',{staticClass:"d-flex justify-content-between pt-3"},[_c('span',{staticClass:"compToDate-price price-textOverflow",attrs:{"title":_vm.activateSimMode ? _vm.compensationToDateSimMode : _vm.compensationToDate}},[(_vm.activateSimMode)?[_vm._v(" "+_vm._s(_vm.compensationToDateSimMode)+" ")]:[_vm._v(" "+_vm._s(_vm.compensationToDate)+" ")]],2),_c('span',{staticClass:"compToDate-title"},[_vm._v(" Comp To Date ")])])]),_c('div',{staticClass:"est-comm-container",on:{"mouseenter":function () {
            _vm.estUnrealizedCommInfoTooltipVisibleIndex = true;
          }}},[_c('div',{staticClass:"est-Unrealized-comm d-flex justify-content-end"},[_c('span',{staticClass:"est-comm-price"},[_vm._v(_vm._s(_vm.estUnpaidCommTotal))]),_c('span',{staticClass:"est-comm-title"},[_vm._v("Est. Unpaid Comm")])]),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[(_vm.estUnrealizedCommInfoTooltipVisibleIndex)?_c('InfoTooltip',{staticClass:"agent-tooltip est-Unrealized-comm-tooltip",attrs:{"data":{
                periodCommTotal: _vm.periodCommTotal,
                unpaidCommTotal: _vm.unpaidCommTotal
              },"infoType":"estUnpaidComm"}}):_vm._e()],1)],1)])])]),_c('div',{staticClass:"page-header-details"}),_c('div',{staticClass:"page-list"},[_c('div',{staticClass:"page-list-container"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" January "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(1)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(1)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" February "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(2)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(2)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" March "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(3)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(3)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" April "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(4)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(4)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" May "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(5)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(5)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" June "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(6)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(6)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" July "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(7)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(7)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" August "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(8)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(8)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" September "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(9)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(9)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" October "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(10)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(10)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" November "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(11)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(11)}}}):_vm._e()])]),_c('th',[_c('div',{staticClass:"head-item"},[_vm._v(" December "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"lock-icon",class:{
                    'activeMonth' : _vm.lockedMonthData.includes(12)
                  },on:{"click":function($event){return _vm.lockEmpPlanMonth(12)}}}):_vm._e()])]),_vm._m(0)])]),_c('tbody',[(_vm.loading)?_c('Loader'):[(
              (
                _vm.activateSimMode &&
                _vm.simModes.manualEntry
              ) ||
              !_vm.activateSimMode
            )?_c('tr',{staticClass:"plan-title plan-header"},[_c('td',{staticClass:"fw-700",attrs:{"colspan":"13"}},[_vm._v("Realized Profit")])]):_vm._e(),(!_vm.activateSimMode)?_c('tr',[_vm._l((_vm.realizedProfitData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              },on:{"mouseleave":function () {
                _vm.showAccountProfitTooltipIndex = -1;
              }}},[(item.ISPROFITAVAILABLE)?[_c('div',{staticClass:"d-flex align-items-center",class:[index === 0 && _vm.realizedAccountProfitData.length ? 'justify-content-between' : 'justify-content-end']},[(index === 0 && _vm.realizedAccountProfitData.length)?_c('button',{staticClass:"btn shadow-none collapsed",attrs:{"data-toggle":"collapse","data-target":".collapse-realized-profit","aria-expanded":true,"aria-controls":"collapse-realized-profit"}},[_c('span',{staticClass:"accordion-open"})]):_vm._e(),(!_vm.lockedMonthData.includes(item.MONTH) && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"pen-icon",attrs:{"title":"Edit"},on:{"click":function () {
                      if (!_vm.lockedMonthData.includes(item.MONTH)) {
                        _vm.realizedProfitMonth = item.MONTH || 0;
                        _vm.showPayrollBatchModal = true;
                        _vm.selectedPayrollCycleMonthAsString = item.MONTHASSTRING || '';
                      }
                    }}}):_vm._e(),_c('span',{staticClass:"actual-price position-relative top-3 textOverflow",attrs:{"title":item.PROFIT_FORMATTED},on:{"mouseenter":function () {
                      _vm.showAccountProfitTooltipIndex = index;
                    }}},[_vm._v(_vm._s(item.PROFIT_FORMATTED))])]),(_vm.showAccountProfitTooltipIndex === index)?_c('TargetProfitTooltip',{attrs:{"empPlanAccountData":item.ACCOUNT_PROFIT,"action":"accountProfit"}}):_vm._e()]:[_c('div',{staticClass:"d-flex align-items-center",class:[index === 0 && _vm.realizedAccountProfitData.length ? 'justify-content-between' : 'justify-content-end']},[(index === 0 && _vm.realizedAccountProfitData.length)?_c('button',{staticClass:"btn shadow-none collapsed",attrs:{"data-toggle":"collapse","data-target":".collapse-realized-profit","aria-expanded":true,"aria-controls":"collapse-realized-profit"}},[_c('span',{staticClass:"accordion-open"})]):_vm._e(),(!_vm.lockedMonthData.includes(item.MONTH) && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"pen-icon",class:{
                      'empty-profit' : index === 0 && _vm.realizedAccountProfitData.length
                    },attrs:{"title":"Edit"},on:{"click":function () {
                      if (!_vm.lockedMonthData.includes(item.MONTH)) {
                        _vm.realizedProfitMonth = item.MONTH || 0;
                        _vm.showPayrollBatchModal = true;
                        _vm.selectedPayrollCycleMonthAsString = item.MONTHASSTRING || '';
                      }
                    }}}):_vm._e(),_vm._v(" - ")])]],2)}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.realizedProfitTotal))])],2):_vm._e(),(
              _vm.activateSimMode &&
              _vm.simModes.manualEntry
            )?_c('tr',[_vm._l((_vm.realizedProfitSimModeData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item.MONTH === 12
              },on:{"mouseleave":function () {
                _vm.showAccountProfitTooltipIndex = -1;
              }}},[_c('span',{staticClass:"editable-new-quote new-quote-name-input currencyInput"},[_c('span',{staticClass:"left"},[_vm._v("$")]),_c('vue-numeric',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.required),expression:"required"},{name:"currencyAll",rawName:"v-currencyAll"}],staticClass:"number initial m-0",class:{ error: _vm.errors.has(item.MONTHASSTRING) },attrs:{"currency":"","separator":",","precision":2,"type":"text","max":999999999.99,"minus":true,"name":item.MONTHASSTRING,"id":item.MONTHASSTRING,"maxlength":12},on:{"blur":function($event){return _vm.updateSimMode(item)}},model:{value:(item.PROFIT),callback:function ($$v) {_vm.$set(item, "PROFIT", _vm._n($$v))},expression:"item.PROFIT"}})],1)])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.realizedProfitSimModeTotal))])],2):_vm._e(),_vm._l((_vm.realizedAccountProfitData),function(item,index){return _c('tr',{key:index,staticClass:"collapse-realized-profit collapse"},[_vm._l((item.ACCOUNT_PROFIT),function(accItem,accIndex){return _c('td',{key:accIndex,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : accItem.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(accItem.MONTH)
              }},[(accIndex === 0 && item.SHOWACCOUNTNAME)?_c('div',{staticClass:"account-header textOverflow",attrs:{"title":item.ANAME}},[_vm._v(" "+_vm._s(item.ANAME)+" ")]):_vm._e(),(accItem.ISPROFITAVAILABLE)?[_c('div',{staticClass:"d-flex justify-content-end"},[(!_vm.lockedMonthData.includes(accItem.MONTH) && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"pen-icon",attrs:{"title":"Add Adjustments"},on:{"click":function () {
                      if (_vm.$systemGroups.includes('financial_admin') && !_vm.lockedMonthData.includes(accItem.MONTH)) {
                        _vm.selectedAdjustmentMonth = accItem.MONTH;
                        _vm.selectedAdjustmentMonthAsString = accItem.MONTHASSTRING;
                        _vm.selectedAdjustmentAID = item.AID;
                        _vm.selectedAdjustmentAccountName = item.ANAME;
                        _vm.accountAdjustmentData = item.ADJUSTMENTS;
                        _vm.showAdjustmentsModal = true;
                        _vm.showAdjustmentMonthDropdown = false;
                      }
                    }}}):_vm._e(),_vm._v(" "+_vm._s(accItem.PROFIT_FORMATTED)+" ")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"account-weight w-25 pr-2 weight-percentage"},[_vm._v(_vm._s(item.COMPWEIGHT)+"%")]),_c('div',{staticClass:"textOverflow account-weight w-75",attrs:{"title":accItem.COMPWEIGHT_PROFIT_FORMATTED}},[_vm._v(_vm._s(accItem.COMPWEIGHT_PROFIT_FORMATTED))])])]:[_c('div',{staticClass:"d-flex justify-content-end"},[(!_vm.lockedMonthData.includes(accItem.MONTH) && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"pen-icon",attrs:{"title":"Add Adjustments"},on:{"click":function () {
                      if (_vm.$systemGroups.includes('financial_admin') && !_vm.lockedMonthData.includes(accItem.MONTH)) {
                        _vm.selectedAdjustmentMonth = accItem.MONTH;
                        _vm.selectedAdjustmentMonthAsString = accItem.MONTHASSTRING;
                        _vm.selectedAdjustmentAID = item.AID;
                        _vm.selectedAdjustmentAccountName = item.ANAME;
                        _vm.accountAdjustmentData = item.ADJUSTMENTS;
                        _vm.showAdjustmentsModal = true;
                        _vm.showAdjustmentMonthDropdown = false;
                      }
                    }}}):_vm._e(),_vm._v(" - ")])]],2)}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(item.ACCOUNT_PROFIT_TOTAL_FORMATTED))])],2)}),(
              _vm.activateSimMode &&
              _vm.simModes.unpaidInvoices
            )?_c('tr',{staticClass:"plan-title plan-header"},[_c('td',{staticClass:"fw-700",attrs:{"colspan":"13"}},[_vm._v("Unpaid Invoices")])]):_vm._e(),(
              _vm.activateSimMode &&
              _vm.simModes.unpaidInvoices
            )?_c('tr',[_vm._l((_vm.unpaidInvData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              }},[_vm._v(_vm._s(item.AMOUNT_FORMATTED))])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.unpaidInvTotal))])],2):_vm._e(),(
              _vm.activateSimMode &&
              _vm.simModes.opportunities
            )?_c('tr',{staticClass:"plan-title plan-header"},[_c('td',{staticClass:"fw-700",attrs:{"colspan":"13"}},[_vm._v("Opportunities")])]):_vm._e(),(
              _vm.activateSimMode &&
              _vm.simModes.opportunities
            )?_c('tr',[_vm._l((_vm.opportunityData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              }},[_vm._v(_vm._s(item.AMOUNT_FORMATTED))])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.opportunityTotal))])],2):_vm._e(),_c('tr',{staticClass:"empty-header"},[_c('td',{staticClass:"fw-700",attrs:{"colspan":"13"}})]),_c('tr',{staticClass:"plan-title plan-header"},[_c('td',{staticClass:"fw-700",attrs:{"colspan":"13"}},[_vm._v("Base")])]),_c('tr',[_vm._l((12),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item)
              }},[_vm._v("$"+_vm._s(_vm._f("formatCurrency")(_vm.details.OTEBASE/12)))])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.details.OTEBASE_FORMATTED))])],2),_c('tr',{staticClass:"plan-title plan-header"},[_c('td',{staticClass:"fw-700",attrs:{"colspan":"13"}},[_vm._v("Commission")])]),(!_vm.activateSimMode)?_c('tr',[_vm._l((_vm.commissionData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              },on:{"mouseleave":function () {
                _vm.showAcceleratorTooltipIndex = -1;
              }}},[_c('span',{staticClass:"d-flex flex-column"},[_c('span',[(item.ISCOMMAVAILABLE)?[_vm._v(" "+_vm._s(item.COMMISSION_FORMATTED)+" ")]:[_vm._v(" - ")]],2)]),_c('div',[_c('div',{staticClass:"accelerators-header textOverflow",attrs:{"title":"Accelerators"}},[(index === 0)?[_vm._v(" Accelerators ")]:_vm._e()],2),_c('span',{staticClass:"accelerators-total",on:{"mouseenter":function () {
                    if((item.ACCELERATORS || []).length) {
                      _vm.showAcceleratorTooltipIndex = index;
                    }
                  }}},[_vm._v(" "+_vm._s(item.ACCELERATORAMOUNT_FORMATTED)+" ")]),(_vm.showAcceleratorTooltipIndex === index)?_c('TargetProfitTooltip',{staticClass:"accelerators-profit-box",class:{
                    'lastRow' : [11,12].includes(item.MONTH)
                  },attrs:{"action":"accelerators","acceleratorData":(item.ACCELERATORS || [])}}):_vm._e()],1)])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.commissionTotal))])],2):_vm._e(),(_vm.activateSimMode)?_c('tr',[_vm._l((_vm.commissionSimModeData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              },on:{"mouseleave":function () {
                _vm.showAcceleratorTooltipIndex = -1;
              }}},[_c('span',{staticClass:"d-flex flex-column"},[_c('span',[(item.ISCOMMAVAILABLE)?[_vm._v(" "+_vm._s(item.COMMISSION_FORMATTED)+" ")]:[_vm._v(" - ")]],2)]),_c('div',[_c('div',{staticClass:"accelerators-header textOverflow",attrs:{"title":"Accelerators"}},[(index === 0)?[_vm._v(" Accelerators ")]:_vm._e()],2),_c('span',{staticClass:"accelerators-total",on:{"mouseenter":function () {
                    if((item.ACCELERATORS || []).length) {
                      _vm.showAcceleratorTooltipIndex = index;
                    }
                  }}},[_vm._v(" "+_vm._s(item.ACCELERATORAMOUNT_FORMATTED)+" ")]),(_vm.showAcceleratorTooltipIndex === index)?_c('TargetProfitTooltip',{staticClass:"accelerators-profit-box",class:{
                    'lastRow' : [11,12].includes(item.MONTH)
                  },attrs:{"action":"accelerators","acceleratorData":(item.ACCELERATORS || [])}}):_vm._e()],1)])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.commissionSimModeTotal))])],2):_vm._e(),_c('tr',{staticClass:"plan-title plan-header"},[_c('td',{attrs:{"colspan":"13"}},[_c('span',{staticClass:"fw-700",class:{
                  'cursor-pointer adjustments-border' : _vm.$systemGroups.includes('financial_admin') && _vm.lockedMonthData.length < 12 && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)
                },on:{"click":function () {
                  if (_vm.$systemGroups.includes('financial_admin') && _vm.lockedMonthData.length < 12 && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                    _vm.selectedAdjustmentMonth = 1;
                    _vm.selectedAdjustmentAID = 0;
                    _vm.selectedAdjustmentAccountName = '';
                    _vm.accountAdjustmentData = [];
                    _vm.showAdjustmentsModal = true;
                    _vm.showAdjustmentMonthDropdown = true;
                  }
                }}},[_vm._v(" Adjustments ")])])]),_c('tr',[_vm._l((_vm.adjustmentData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              }},[_c('div',{staticClass:"d-flex justify-content-end"},[(!_vm.lockedMonthData.includes(item.MONTH) && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"pen-icon",attrs:{"title":"Edit"},on:{"click":function () {
                    if (_vm.$systemGroups.includes('financial_admin') && !_vm.lockedMonthData.includes(item.MONTH)) {
                      _vm.selectedAdjustmentMonth = item.MONTH;
                      _vm.selectedAdjustmentMonthAsString = item.MONTHASSTRING;
                      _vm.selectedAdjustmentAID = 0;
                      _vm.selectedAdjustmentAccountName = '';
                      _vm.accountAdjustmentData = [];
                      _vm.showAdjustmentsModal = true;
                      _vm.showAdjustmentMonthDropdown = false;
                    }
                  }}}):_vm._e(),(item.AMOUNT_FORMATTED && item.AMOUNT)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.adjustmentsTotal))])],2),_c('tr',{staticClass:"plan-title plan-header"},[_c('td',{attrs:{"colspan":"13"}},[_c('span',{staticClass:"fw-700",class:{
                  'cursor-pointer adjustments-border' : _vm.$systemGroups.includes('financial_admin') && _vm.lockedMonthData.length < 12 && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)
                },on:{"click":function () {
                  if (_vm.$systemGroups.includes('financial_admin') && _vm.lockedMonthData.length < 12 && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin)) {
                    _vm.selectedReimursementMonth = 1;
                    _vm.showReimursementsModal = true;
                    _vm.showReimursementMonthDropdown = true;
                  }
                }}},[_vm._v(" Reimbursements ")])])]),_c('tr',[_vm._l((_vm.reimursementData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              }},[_c('div',{staticClass:"d-flex justify-content-end"},[(!_vm.lockedMonthData.includes(item.MONTH) && _vm.$allowedFunctions.includes(_vm.$userFunctions.CompAdmin))?_c('span',{staticClass:"pen-icon",attrs:{"title":"Edit"},on:{"click":function () {
                    if (_vm.$systemGroups.includes('financial_admin') && !_vm.lockedMonthData.includes(item.MONTH)) {
                      _vm.selectedReimursementMonth = item.MONTH;
                      _vm.selectedReimursementMonthAsString = item.MONTHASSTRING;
                      _vm.showReimursementsModal = true;
                      _vm.showReimursementMonthDropdown = false;
                    }
                  }}}):_vm._e(),(item.AMOUNT_FORMATTED && item.AMOUNT)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.reimursementsTotal))])],2),_c('tr',{staticClass:"plan-title"},[_c('td',{staticClass:"fw-700",attrs:{"colspan":"13"}},[_vm._v("Total Compensation")])]),(!_vm.activateSimMode)?_c('tr',{staticClass:"total-Compensation"},[_vm._l((_vm.totalCompensationData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-700",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              }},[(item.AMOUNT_FORMATTED)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.totalCompensationTotal))])],2):_vm._e(),(_vm.activateSimMode)?_c('tr',{staticClass:"total-Compensation"},[_vm._l((_vm.totalCompensationSimModeData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-700",class:{
                'plan-dark-border-right' : item.MONTH === 12,
                'activeMonth' : _vm.lockedMonthData.includes(item.MONTH)
              }},[(item.AMOUNT_FORMATTED)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(_vm.totalCompensationSimModeTotal))])],2):_vm._e()]],2)])])]),_c('div',{staticClass:"clearfix"}),_c('add-footer'),(_vm.showCommTargetModal)?_c('ModalEditCommTarget',{attrs:{"empPlanAccountData":_vm.empPlanAccountData,"data":_vm.details,"businessLineOptions":_vm.businessLineOptions,"targetsTotal":_vm.allTargetsTotal,"realizedProfitTotal":_vm.activateSimMode ? _vm.realizedProfitSimModeTotal : _vm.realizedProfitTotal},on:{"close":function () {
      _vm.showCommTargetModal = false;
    },"reload":function($event){return _vm.reloadPlan(true)}}}):_vm._e(),(_vm.showAdjustmentsModal)?_c('AdjustmentsModal',{attrs:{"adjustmentData":_vm.selectedAdjustmentAID ? _vm.accountAdjustmentData : _vm.adjustmentData,"data":_vm.details,"lockedMonthData":_vm.lockedMonthData,"selectedAdjustmentMonth":_vm.selectedAdjustmentMonth,"showAdjustmentMonthDropdown":_vm.showAdjustmentMonthDropdown,"selectedAdjustmentMonthAsString":_vm.selectedAdjustmentMonthAsString,"selectedAdjustmentAID":_vm.selectedAdjustmentAID,"selectedAdjustmentAccountName":_vm.selectedAdjustmentAccountName},on:{"close":function () {
      _vm.showAdjustmentsModal = false;
      _vm.showAdjustmentMonthDropdown = false;
    },"reload":function($event){return _vm.reloadPlan()}}}):_vm._e(),(_vm.showPayrollBatchModal)?_c('PayrollBatchesModal',{attrs:{"payrollBatchesData":_vm.payrollBatchesData,"realizedProfitBatchesData":_vm.realizedProfitBatchesData,"selectedPayrollCycleMonthAsString":_vm.selectedPayrollCycleMonthAsString,"month":_vm.realizedProfitMonth,"data":_vm.details},on:{"close":function () {
      _vm.showPayrollBatchModal = false;
      _vm.realizedProfitMonth = 0;
    },"reload":function($event){return _vm.reloadPlan(true)}}}):_vm._e(),(_vm.showReimursementsModal)?_c('ReimursementsModal',{attrs:{"reimursementData":_vm.reimursementData,"data":_vm.details,"lockedMonthData":_vm.lockedMonthData,"selectedReimursementMonth":_vm.selectedReimursementMonth,"showReimursementMonthDropdown":_vm.showReimursementMonthDropdown,"selectedReimursementMonthAsString":_vm.selectedReimursementMonthAsString},on:{"close":function () {
      _vm.showReimursementsModal = false;
      _vm.showReimursementMonthDropdown = false;
    },"reload":function($event){return _vm.reloadPlan()}}}):_vm._e(),(_vm.showAcceleratorsModal)?_c('AcceleratorsModal',{attrs:{"acceleratorData":_vm.acceleratorData,"data":_vm.details},on:{"close":function () {
      _vm.showAcceleratorsModal = false;
    },"reload":function($event){return _vm.reloadPlan()}}}):_vm._e(),(_vm.showSimModeModal)?_c('SimModeModal',{attrs:{"simModes":_vm.simModes},on:{"save":function (data) { return _vm.updateSimModal(data); },"close":function () {
      _vm.showSimModeModal = false;
    }}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('div',{staticClass:"head-item"},[_vm._v("Total")])])}]

export { render, staticRenderFns }