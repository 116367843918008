import { DirectiveBinding } from "vue/types/options"
import { VNode } from "vue"

import { wait } from '../models/common'

function isEdgeBrowser(): boolean {
  return window.navigator.userAgent.indexOf("Edg") > -1 // Edg is for the new Chromium based Edge
}

export default {
  digitOnly: {
    update(
      elem: HTMLElement,
      _: DirectiveBinding,
      vNode: VNode,
      oldVnode: VNode,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      const newValue = vNode!.data!.domProps!.value
      const oldValue = oldVnode!.data!.domProps!.value

      if (/^(\d)*$/.test(newValue)) {
        return
      }

      var event = new Event('input', { bubbles: true })
      el.value = oldValue
      el.dispatchEvent(event)
    },
  },
  numericOnly: {
    update(
      elem: HTMLElement,
      _: DirectiveBinding,
      vNode: VNode,
      oldVnode: VNode,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      const newValue = vNode!.data!.domProps!.value
      const oldValue = oldVnode!.data!.domProps!.value

      if (/^([\d.])*$/.test(newValue)) {
        return
      }

      var event = new Event('input', { bubbles: true })
      el.value = oldValue
      el.dispatchEvent(event)
    },
  },
  currencyOnly: {
    update(
      elem: HTMLElement,
      _: DirectiveBinding,
      vNode: VNode,
      oldVnode: VNode,
    ) {
      try {
        const oldValue = oldVnode!.data!.domProps ? oldVnode!.data!.domProps!.value : oldVnode!.data!["model"]!.value
        const el: HTMLInputElement = elem as HTMLInputElement
        elem.dataset.value = oldValue
      }catch(err) {
        //console.log(err);
      }
    },
    bind(
      elem: HTMLElement,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      el.addEventListener('input', function (e) {

        const newValue = el.value

        if (/^([\d.,])*$/.test(newValue)) {
          return
        }

        var event = new Event('input', { bubbles: true })
        el.value = elem.dataset.value || ''
        el.dispatchEvent(event)
      })
    },
  },
  letterOnly: {
    update(
      elem: HTMLElement,
      _: DirectiveBinding,
      vNode: VNode,
      oldVnode: VNode,
    ) {
      try {
        const oldValue = oldVnode!.data!.domProps ? oldVnode!.data!.domProps!.value : oldVnode!.data!["model"]!.value
        const el: HTMLInputElement = elem as HTMLInputElement
        elem.dataset.value = oldValue
      }catch(err) {
        //console.log(err);
      }
    },
    bind(
      elem: HTMLElement,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      el.addEventListener('input', function (e) {

        const newValue = el.value

        if (/^[a-zA-Z0-9_.-]*$/.test(newValue)) {
          return
        }

        var event = new Event('input', { bubbles: true })
        el.value = elem.dataset.value || ''
        el.dispatchEvent(event)
      })
    },
  },
  skuOnly: {
    update(
      elem: HTMLElement,
      _: DirectiveBinding,
      vNode: VNode,
      oldVnode: VNode,
    ) {
      try {
        const oldValue = oldVnode!.data!.domProps ? oldVnode!.data!.domProps!.value : oldVnode!.data!["model"]!.value
        const el: HTMLInputElement = elem as HTMLInputElement
        elem.dataset.value = oldValue
      }catch(err) {
        //console.log(err);
      }
    },
    bind(
      elem: HTMLElement,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      el.addEventListener('input', function (e) {

        const newValue = el.value

        if (/^[a-zA-Z0-9#_.-]*$/.test(newValue)) {
          return
        }

        var event = new Event('input', { bubbles: true })
        el.value = elem.dataset.value || ''
        el.dispatchEvent(event)
      })
    },
  },
  currencyAll: {
    update(
      elem: HTMLElement,
      _: DirectiveBinding,
      vNode: VNode,
      oldVnode: VNode,
    ) {
      const oldValue = oldVnode!.data!.domProps ? oldVnode!.data!.domProps!.value : oldVnode!.data!["model"]!.value
      const el: HTMLInputElement = elem as HTMLInputElement
      elem.dataset.value = oldValue
    },
    bind(
      elem: HTMLElement,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      el.addEventListener('input', function (e) {

        const newValue = el.value

        if (/^([\d.,-])*$/.test(newValue)) {
          return
        }

        var event = new Event('input', { bubbles: true })
        el.value = elem.dataset.value || ''
        el.dispatchEvent(event)
      })
    },
  },
  pasteTrim: {
    bind(
      elem: HTMLElement,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      el.addEventListener('paste', async function (e) {

        await wait(0)

        const newValue = el.value

        const trimValue = newValue.trim()

        if (trimValue !== newValue) {
          const event = new Event('input', { bubbles: true })
          el.value = trimValue
          el.dispatchEvent(event)
        }
      })
    },
  },
  ellipsisInput: {
    bind(
      elem: HTMLElement,
    ) {
      if (!isEdgeBrowser()) {
        return
      }

      const el: HTMLInputElement = elem as HTMLInputElement
      if (el.tagName == "INPUT") {
        el.setAttribute('readonly', 'readonly')
      } else {
        el.getElementsByTagName("input")[0].setAttribute('readonly', 'readonly')
      }
      el.addEventListener('focus', function (e) {
        if (el.tagName == "INPUT") {
          el.removeAttribute('readonly')
        } else {
          el.getElementsByTagName("input")[0].removeAttribute('readonly')
        }
      })
      el.addEventListener('mouseenter', function (e) {
        if (el.tagName == "INPUT") {
          el.removeAttribute('readonly')
        } else {
          el.getElementsByTagName("input")[0].removeAttribute('readonly')
        }
      })
      el.addEventListener('mouseleave', function (e) {
        if (el.tagName == "INPUT") {
          el.setAttribute('readonly', 'readonly')
        } else {
          el.getElementsByTagName("input")[0].setAttribute('readonly', 'readonly')
        }
      })
      el.addEventListener('blur', function (e) {
        if (el.tagName == "INPUT") {
          el.setAttribute('readonly', 'readonly')
        } else {
          el.getElementsByTagName("input")[0].setAttribute('readonly', 'readonly')
        }
      })
    },
  },
  priceOnly: {
    update(
      elem: HTMLElement,
      _: DirectiveBinding,
      vNode: VNode,
      oldVnode: VNode,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement
      var newValue = vNode!.data!.domProps ?  vNode!.data!.domProps!.value :  vNode!.data!["model"]!.value
      var oldValue = oldVnode!.data!.domProps ? oldVnode!.data!.domProps!.value : oldVnode!.data!["model"]!.value
      if(isNaN(newValue)){
        newValue = newValue.replace(/[^0-9.,]+/, '')
      }
      if(isNaN(newValue)){
        oldValue = oldValue.replace(/[^0-9.,]+/, '')
      }
      if(newValue.toString().length > 8 && el.dataset.value){
        if(!/^\d+$/.test(el.dataset.value)){
          el.maxLength = 12;
        }
      }
      if (/^([\d.])*$/.test(newValue)) {
        return
      }

      var event = new Event('input', { bubbles: true })
      el.value = oldValue
      el.dispatchEvent(event)
    },
    bind(
      elem: HTMLElement,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      el.addEventListener('input', function (e) {

        el.value = el.value.replace(/[^0-9.,]+/, '');
        elem.dataset.value = el.value.replace(/[^0-9.,]+/, '');
        // el.value = parseFloat(el.value).toFixed(2);
        const newValue = el.value
        if (/^([\d.,])*$/.test(newValue)) {
          el.value = el.value.replace(/[^0-9.,]+/, '');
          elem.dataset.value = el.value.replace(/[^0-9.,]+/, '');
          if(el.value.toString().length == 9){
            // el.value = parseFloat(el.value).toFixed(2);
            // elem.dataset.value = parseFloat(el.value).toFixed(2);
            var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if(isFirefox){
              // el.blur()
            }
          }
          if(!/^\d{0,8}(\.\d{1,2})?$/.test(newValue)){
            // el.value = parseFloat(el.value).toFixed(2);
            return;
          }else{
            return
          }
        }
        var event = new Event('input', { bubbles: true })
        el.value = elem.dataset.value || '';
        el.value = parseFloat(el.value).toFixed(2);
        el.dispatchEvent(event)
      })
    },
  },
  marginOnly: {
    update(
      elem: HTMLElement,
      _: DirectiveBinding,
      vNode: VNode,
      oldVnode: VNode,
    ) {
      try {
        const oldValue = oldVnode!.data!.domProps ? oldVnode!.data!.domProps!.value : oldVnode!.data!["model"]!.value
        var newValue = vNode!.data!.domProps ?  vNode!.data!.domProps!.value :  vNode!.data!["model"]!.value
        if(isNaN(newValue)){
          newValue = newValue.replace(/[^0-9.,-]+/, '')
        }
        const el: HTMLInputElement = elem as HTMLInputElement
        elem.dataset.value = newValue
      }catch(err) {
        //console.log(err);
      }
    },
    bind(
      elem: HTMLElement,
    ) {
      const el: HTMLInputElement = elem as HTMLInputElement

      el.addEventListener('input', function (e) {

        // el.value = parseFloat(el.value).toFixed(2);
        const newValue = el.value
        if (/^([\d.,-])*$/.test(newValue)) {
          return
        }
        var event = new Event('input', { bubbles: true })
        el.value = elem.dataset.value || '';
        el.dispatchEvent(event)
      })
    },
  },
}
