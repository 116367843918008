import axios from "axios";
import { notifier } from "@/models/common";

declare const dataURL: string;
declare const getMaxRows: Function;

export type Filters = {
	order: string
	searchAccountName: string
	sDate: string | undefined
	eDate: string | undefined
	OrderTotaltMin: string | undefined
  OrderTotaltMax: string | undefined
	InvoiceTotaltMin: string | undefined
	InvoiceTotaltMax: string | undefined
	pStatus: number[]
	TotalInvoiceMin: string | undefined
	TotalInvoiceMax: string | undefined
	NotInvoicedMin: string | undefined
	NotInvoicedMax: string | undefined
	AccountTagID: number[]
	selectedAccs: (number | undefined)[]
	groupIDs: number[]
	gTagIDs: number[]
	accountTagName: (string | undefined)[]
	selectedAccsName: (string | undefined)[]
	groupName: (string | undefined)[]
}

export enum SortField {
	Order = 1,
  Customer = 2,
  Total = 3,
  Date = 4,
  OrderTotal = 5,
  InvoiceTotal = 6,
	FullPayment = 7,
	NotInvoicedTotal = 8
}

export type Sort = {
	field: SortField | null
  direction: {
		[SortField.Order]: 1 | 2,
    [SortField.Customer]: 1 | 2,
    [SortField.Total]: 1 | 2,
    [SortField.Date]: 1 | 2,
    [SortField.OrderTotal]: 1 | 2,
    [SortField.InvoiceTotal]: 1 | 2,
    [SortField.FullPayment]: 1 | 2,
	[SortField.NotInvoicedTotal]: 1 | 2
  }
}

export class AuditAPIRepo {
	async find(filters: Filters, sort: Sort, pageNumber: number, viewType = "CurrentUsers", controllerName = "Invoices") {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType, controllerName);
    reqData["FunctionName"] = "Audit";
    reqData["maxRows"] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows();
    reqData["PageNumber"] = pageNumber.toString();
		reqData["Default_totalRows"] = getMaxRows();
		reqData["purchaseID"] = filters.order;
		reqData["sAccountName"] = filters.searchAccountName;
		

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

	prepareFilteredData(filters: any, sort: any, viewType: string = 'CurrentUsers', controllerName: string ) {
		var ret = {
      "controller": controllerName,
			"view": viewType,
			"selectedTagIDs": filters.AccountTagID.join(","),
			"selectedAccountDs": filters.selectedAccs.join(",")
		}

		if (filters.sDate) {
      ret['sDate'] = filters.sDate
    }
    if (filters.eDate) {
      ret['eDate'] = filters.eDate
		}
		if (typeof filters.OrderTotaltMin !== 'undefined') {
      ret['OrderTotaltMin'] = filters.OrderTotaltMin
    }
    if (typeof filters.OrderTotaltMax !== 'undefined') {
      ret['OrderTotaltMax'] = filters.OrderTotaltMax
    }
		if (typeof filters.InvoiceTotaltMin !== 'undefined') {
      ret['InvoiceTotaltMin'] = filters.InvoiceTotaltMin
    }
    if (typeof filters.InvoiceTotaltMax !== 'undefined') {
      ret['InvoiceTotaltMax'] = filters.InvoiceTotaltMax
		}
		ret['pStatus'] = '-1';
		if (typeof filters.pStatus !== 'undefined' && filters.pStatus.length > 0) {
			ret['pStatus'] = filters.pStatus.join(',')
		}
		if (typeof filters.TotalInvoiceMin !== 'undefined') {
			ret['TotalInvoiceMin'] = filters.TotalInvoiceMin
		}
		if (typeof filters.TotalInvoiceMax !== 'undefined') {
			ret['TotalInvoiceMax'] = filters.TotalInvoiceMax
		}
		if (typeof filters.NotInvoicedMin !== 'undefined') {
			ret['NotInvoicedMin'] = filters.NotInvoicedMin
		}
		if (typeof filters.NotInvoicedMax !== 'undefined') {
			ret['NotInvoicedMax'] = filters.NotInvoicedMax
		}
		if (sort.field) {
      ret["order"] = sort.field
      ret["direction"] = sort.direction[sort.field].toString()
    }
		ret["groupIDs"] = (typeof filters["groupIDs"] != "undefined" ? filters["groupIDs"].join(",") : "");
		ret["gTagIDs"] = (typeof filters["gTagIDs"] != "undefined" ? filters["gTagIDs"].join(",") : "");

		return ret;
	}
}

